import React from 'react';
import NavBar from '../components/NavBar';
import SpeakersShow from '../components/SpeakersShow';

const Speakers = () => (
  <div>
    <NavBar />
    <SpeakersShow />
  </div>
);

export default Speakers;
