import React from 'react';
import NavBar from '../components/NavBar';
import RennesText from '../components/RennesText';

const LeChoixdeRennes = () => (
  <div>
    <NavBar />
    <div className="flex flex-col justify-center align-center px-5 mx-3">
      <RennesText />
    </div>
  </div>
);

export default LeChoixdeRennes;
