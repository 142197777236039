import React from 'react';
import NavBar from '../components/NavBar';
import SponsorsLogo from '../components/SponsorsLogo';

const SponsorsAndPartners = () => (
  <div className="">
    <NavBar />
    <div className="px-10">
      <SponsorsLogo />
    </div>
  </div>
);

export default SponsorsAndPartners;
