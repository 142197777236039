import React from 'react';
import IleVilaine from '../images/IleVilaine.jpg';
import UAIR from '../images/UAIR.jpg';
import AIPP from '../images/AIPP.png';
// import NYOTA from '../images/NYOTA.jpg';
import WAZA from '../images/WAZA.jpg';
import applx from '../images/applx.jpg';
import GJ from '../images/GJ.jpg';
import MATONGE from '../images/matonge.jpg';
// import MM2 from '../images/mm2.jpg';
import APLDD from '../images/newlogo/apldd.jpeg';
// import ESPACEMARCELINE from '../images/newlogo/espaceMarcelline.jpeg';
import FLAK from '../images/newlogo/flak.jpeg';
import FONDATIONKASSAANTOINE from '../images/newlogo/fondationKassaAntoine.jpeg';
import KONGORIVER from '../images/newlogo/KongoRiver.png';
import MAGASINDEPAYSANS from '../images/newlogo/magasinDePaysans.jpg';
import MAISONBRETAGNE from '../images/newlogo/maisonBretagne.jpeg';
import MAISONINTERNATIONALEDERENNES from '../images/newlogo/maisonInternationalDeRenne.jpeg';
import MURO from '../images/newlogo/muro.jpeg';
import EDIAESSO from '../images/newlogo/ediaAsso.jpg';

const SponsorsLogo = () => (
  <div className="pb-10 mx-3 pt-5 my-10 flex flex-col align-between gap-2">
    <p className="text-4xl text-bold text-center lg:text-left  lg:mx-20  my-5 ">Sponsors & Partners</p>
    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6  px-5 py-2 mx-3 space-x-1 sm:space-x-4">
      <a href="www.applx.com" className="">
        <img src={applx} className=" h-2/2 sm:h-2/2" alt="Applx logo" />
      </a>

      <a href="https://metropole.rennes.fr/organisme/union-des-associations-interculturelles-de-rennes-uair-250" className="">
        <img src={UAIR} className="h-2/2" alt="UAIR logo" />
      </a>

      {/* <a href="https://nyotaafricaetoileafricaine.com/" >
              <img src={NYOTA} className="h-2/2" alt="Nyota logo" />
            </a> */}

      <a href="https://www.facebook.com/artisansdilleetvilaine" className="">
        <img src={IleVilaine} className="h-2/2" alt="Artisans d’Ile et Vilaine logo" />
      </a>

      <a href="https://www.rdc145territoires.com/" className="">
        <img src={WAZA} className="h-2/2" alt="WAZA logo" />
      </a>

      <a href="https://www.grandjournalcd.com" className="">
        <img src={GJ} className="h-2/2" alt="GJ logo" />
      </a>

      <a href="https://www.rdc145territoires.com/" className="">
        <img src={AIPP} className="h-2/2" alt="AIPP logo" />
      </a>

      <a href="https://www.rdc145territoires.com/" className="">
        <img src={MATONGE} className="h-2/2" alt="MATONGE logo" />
      </a>

      <a href="https://www.presidence.cd/" className="">
        <img src="https://ik.imagekit.io/pamphilemkpchill/BjHreLZV_400x400.jpg?updatedAt=1691684769827" className="h-2/2" alt="RDC presidence logo" />
      </a>

      {/* <a href="https://mm2-online.com/" className="">
               <img src={MM2} className="h-2/2" alt="mm2 logo" />
            </a> */}

      <a href="https://apldd.be/" className="">
        <img src={APLDD} className="h-2/2" alt="APLDD logo" />
      </a>

      {/* <a href="https://espacemarcelline942002062.wordpress.com/" className="">
               <img src={ESPACEMARCELINE} className="h-2/2" alt="Espace Marceline logo" />
            </a> */}

      <a href="https://m.facebook.com/p/Fondation-agape-RDC-100069640837539/" className="">
        <img src={FLAK} className="h-2/2" alt="F.L.A.K logo" />
      </a>

      <a href="https://www.facebook.com/kassaantointe.fka/" className="">
        <img src={FONDATIONKASSAANTOINE} className="h-2/2" alt="Fondation Kassa Antoine logo" />
      </a>

      <a href="https://kongoriver.com/" className="">
        <img src={KONGORIVER} className="h-2/2" alt="Kongo river logo" />
      </a>

      <a href="https://brindherbe35.fr/les-magasins/" className="">
        <img src={MAGASINDEPAYSANS} className="h-2/2" alt="magasin des paysans logo" />
      </a>

      <a href="https://www.bretagne.bzh/" className="">
        <img src={MAISONBRETAGNE} className="h-2/2" alt="Maison Bretagne logo" />
      </a>

      <a href="https://www.mir-rennes.fr/" className="">
        <img src={MAISONINTERNATIONALEDERENNES} className="h-2/2" alt="Maison internation de rennes logo" />
      </a>

      <a href="https://www.mir-rennes.fr/" className="">
        <img src={MURO} className="h-2/2" alt="Muro logo" />
      </a>

      <a href="https://www.facebook.com/p/EDIA-ASSO-27-100076475739936/" className="">
        <img src={EDIAESSO} className="h-2/2" alt="Edia Asso logo" />
      </a>

    </div>
  </div>
);

export default SponsorsLogo;
