/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addParticipants, getParticipants } from '../redux/participant/Store';
import '../App.css';

const ParticipantForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [receipt, setReceipt] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [citizenship, setCitizenship] = useState('');
  const [proffession, setProfession] = useState('');
  const [experience, setProffessionalExperience] = useState('');
  const [idea, setIdea] = useState('');
  const [impactOfIdea, setImpactOfIdea] = useState('');
  const [sectorOfInvestment, setSectorOfInvestment] = useState('');
  const [provinceOfInterest, setProvinceOfInterest] = useState('');
  const [projectSummary, setProjectSummary] = useState('');
  const [errors, setErrors] = useState({});

  // form redirection and redux state access
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notify = () => toast.success('Votre candidature  a été envoyé avec succès !', {
    position: 'top-right',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });

  // react select option with tailwindcss
  const options = [
    { value: 'Mines', label: 'Mines' },
    { value: 'Energie', label: 'Energie' },
    { value: 'Transport', label: 'Transport' },
    { value: 'Télécommunication', label: 'Télécommunication' },
    { value: 'Tourisme', label: 'Tourisme' },
    { value: 'Santé', label: 'Santé' },
    { value: 'Industrie et transformation locale', label: 'Industrie et transformation locale' },
    { value: 'Numérique', label: 'Numérique' },
    { value: 'Infrastructures', label: 'Infrastructures' },
    { value: 'Assurances', label: 'Assurances' },
    { value: 'Agriculture, Agroalimentaire', label: 'Agriculture, Agroalimentaire' },
    { value: 'Autres', label: 'Autres' },

  ];

  const optionList = [
    { value: 'Haut-Katanga', label: 'Haut-Katanga' },
    { value: 'Kinshasa', label: 'Kinshasa' },
    { value: 'Kongo Central', label: 'Kongo Central' },
    { value: 'Kwango', label: 'Kwango' },
    { value: 'Kwilu', label: 'Kwilu' },
    { value: 'Mai-Ndombe', label: 'Mai-Ndombe' },
    { value: 'Kasaï', label: 'Kasaï' },
    { value: 'Kasaï-Central', label: 'Kasaï-Central' },
    { value: 'Kasaï-Oriental', label: 'Kasaï-Oriental' },
    { value: 'Lomami', label: 'Lomami' },
    { value: 'Sankuru', label: 'Sankuru' },
    { value: 'Maniema', label: 'Maniema' },
    { value: 'Sud Kivu', label: 'Sud Kivu' },
    { value: 'Nord Kivu', label: 'Nord Kivu' },
    { value: 'Ituri', label: 'Ituri' },
    { value: 'Haut-Uele', label: 'Haut-Uele' },
    { value: 'Tshopo', label: 'Tshopo' },
    { value: 'Bas-Uele', label: 'Bas-Uele' },
    { value: 'Nord-Ubangi', label: 'Nord-Ubangi' },
    { value: 'Mongala', label: 'Mongala' },
    { value: 'Sud-Ubangi', label: 'Sud-Ubangi' },
    { value: 'Équateur', label: 'Équateur' },
    { value: 'Tshuapa', label: 'Tshuapa' },
    { value: 'Tanganyika', label: 'Tanganyika' },
    { value: 'Haut-Lomami', label: 'Haut-Lomami' },
    { value: 'Lualaba', label: 'Lualaba' },

  ];

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    // for first name

    if (firstName.length === 1 || firstName.length <= 3) {
      formIsValid = false;
      newErrors.firstName = 'Votre nom doit avoir plus 3 lettres';
    }

    if (firstName.trim() === '') {
      formIsValid = false;
      newErrors.firstName = 'Please entrez votre name';
    }

    // for last name

    if (lastName.length === 1 || lastName.length <= 3) {
      formIsValid = false;
      newErrors.lastName = 'Votre nom doit avoir plus 3 lettres';
    }

    if (lastName.trim() === '') {
      formIsValid = false;
      newErrors.firstName = 'Please entrez votre name';
    }

    // for the payment receipt

    if (receipt.length === 1 || receipt.length <= 6) {
      formIsValid = false;
      newErrors.receipt = 'Le numéro du réçu de payment a plus 6 lettres';
    }

    if (receipt.trim() === '') {
      formIsValid = false;
      newErrors.receipt = 'Please entrez le numéro du réçu de payment';
    }

    // for email

    if (!email) {
      formIsValid = false;
      newErrors.email = 'Please entrez votre email';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      newErrors.email = 'Please entrez votre un email valide';
    }

    // for phone number

    if (!isValidPhoneNumber(phone)) {
      formIsValid = false;
      newErrors.phone = 'Please entrez un numero de telephone valide';
    } else if (phone.trim() === '') {
      formIsValid = false;
      newErrors.phone = 'Le numero de telephone est obligatoire';
    }

    // for address

    if (address.length === 1 || address.length <= 15) {
      formIsValid = false;
      newErrors.address = 'Votre adress doit avoir plus 3 mots';
    }

    if (!address) {
      formIsValid = false;
      newErrors.address = 'Please entrez votre adresse';
    }

    // Proffession

    if (proffession.length === 1 || proffession.length <= 3) {
      formIsValid = false;
      newErrors.proffession = 'Votre proffession doit avoir plus 3 lettres';
    }

    if (!proffession) {
      formIsValid = false;
      newErrors.proffession = 'Please, dites-nous votre proffession';
    }

    // experience
    if (experience.length === 1 || experience.length <= 3) {
      formIsValid = false;
      newErrors.experience = 'Votre type de sponsorship doit avoir au moins un mot';
    }

    if (!experience) {
      formIsValid = false;
      newErrors.experience = 'Please dites nous quel type de sponsorship vous voulez nous apporter';
    }

    // country of residence
    if (citizenship.length === 1 || citizenship.length <= 3) {
      formIsValid = false;
      newErrors.citizenship = 'Votre pays de residence doit etre correct';
    }

    if (!experience) {
      formIsValid = false;
      newErrors.citizenship = 'Please, enterez votre pays de residence';
    }

    // for idea

    if (idea.length === 1 || idea.length <= 15) {
      formIsValid = false;
      newErrors.idea = 'Votre idée doit avoir plus 5 mots';
    }

    if (!idea) {
      formIsValid = false;
      newErrors.projectSummary = 'Please, entrez votre idée d\'investissement';
    }

    // for impactOfIdea

    if (impactOfIdea.length === 1 || impactOfIdea.length <= 15) {
      formIsValid = false;
      newErrors.impactOfIdea = 'L\'impact de votre idée doit avoir plus 5 mots';
    }

    if (!impactOfIdea) {
      formIsValid = false;
      newErrors.impactOfIdea = 'Please, entrez l\'impact de votre idée d\'investissement';
    }

    // Sector of investment

    if (!sectorOfInvestment) {
      formIsValid = false;
      newErrors.sectorOfInvestment = 'Please, entrez votre secteur d\'investissement';
    }

    // for province of interest

    if (!provinceOfInterest) {
      formIsValid = false;
      newErrors.provinceOfInterest = 'Please, entrez votre secteur d\'interèt';
    }

    // for projectSummary

    if (projectSummary.length === 1 || projectSummary.length <= 5) {
      formIsValid = false;
      newErrors.projectSummary = 'Votre resume du projet doit avoir plus 5 lettres';
    }

    if (!projectSummary) {
      formIsValid = false;
      newErrors.projectSummary = 'Please entrez votre resume du projet';
    }

    setErrors(newErrors);
    return formIsValid;
  };

  // SUBMIT ALL THE DATA FOR PROCESSING IN THE STORE MANAGEMENT IN THIS CASE ADD
  const data = {
    first_name: firstName,
    last_name: lastName,
    email,
    receipt,
    phone,
    address,
    proffession,
    country_of_residence: citizenship,
    investment_idea: idea,
    impact_of_idea: impactOfIdea,
    sector_of_investment: sectorOfInvestment,
    proffessional_experience: experience,
    summary_of_project: projectSummary,
    province_of_interest: provinceOfInterest,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Handle form submission

      navigate('/');
      dispatch(addParticipants(data));
      notify();
    }
  };

  // testing if we are getting the data
  useEffect(() => {
    dispatch(getParticipants());
  }, [dispatch]);

  // All about select options

  const handleSelect = (event) => {
    setSectorOfInvestment(event);
  };

  const handleMultiSelect = (event) => {
    setProvinceOfInterest(event);
  };

  return (
    <div className="shadow-2xl w-5/6 mx-auto p-10 mt-3 bg-gray-100 mb-6 rounded">

      <p className="mb-5 text-lg font-medium">
        {`
      S'il vous plait, Veuillez completer tous les champs
       de ce formulaire pour que votre candidature
        soit pris en considération.`}
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">

        {/* Fisrt Name */}

        <div>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
            Votre Nom
          </label>
          <div className="mt-1">
            <input
              id="name"
              type="text"
              name="firstName"
              placeholder="Ex: Musonda"
              className={`input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
             focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
             dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.firstName ? 'input-error' : ''}`}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {errors.firstName && <p className="input-error-projectSummary text-red-700">{errors.firstName}</p>}
          </div>
        </div>

        {/* Last Name */}

        <div>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
            Votre prenom
          </label>
          <div className="mt-1">
            <input
              id="name"
              type="text"
              name="name"
              placeholder="Ex: Pamphile"
              className={`input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
             focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
             dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.lastName ? 'input-error' : ''}`}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {errors.lastName && <p className="input-error-projectSummary text-red-700">{errors.lastName}</p>}
          </div>
        </div>

        {/* Email */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="email"
              name="email"
              placeholder="ex: edmjvnews@gmail.com"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
            dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p className="input-error-projectSummary text-red-700">{errors.email}</p>}
          </div>
        </div>

        {/* receipt */}

        <div>
          <label htmlFor="receipt" className="block text-sm font-medium ">
            Numéro de votre réçu de payment
          </label>
          <div className="mt-1">
            <input
              id="receipt"
              type="text"
              name="receipt"
              placeholder="ex: 1777-7777"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
            dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={receipt}
              onChange={(e) => setReceipt(e.target.value)}
            />
            {errors.receipt && <p className="input-error-projectSummary text-red-700">{errors.receipt}</p>}
          </div>
        </div>

        {/* Phone number */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Votre numero de téléphone
          </label>
          <div className="mt-1">
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="FR"
              value={phone}
              onChange={setPhone}
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm
                        rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 `}
            />
            {errors.phone && <p className="input-error-projectSummary text-red-700">{errors.phone}</p>}
          </div>
        </div>

        {/* Address */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Addresse
          </label>
          <div className="mt-1">
            <input
              id="addresse"
              type="text"
              name="address"
              placeholder="Votre adresse complete..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
            dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            {errors.address && <p className="input-error-projectSummary text-red-700">{errors.address}</p>}
          </div>
        </div>

        {/* Country of residence */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Pays de residence
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="text"
              name="email"
              placeholder="Ex: Australie"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
            dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.citizenship ? 'input-error' : ''}`}
              value={citizenship}
              onChange={(e) => setCitizenship(e.target.value)}
            />
            {errors.citizenship && <p className="input-error-projectSummary text-red-700">{errors.citizenship}</p>}
          </div>
        </div>

        {/* Proffessional experience */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Experience
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="text"
              name="experience"
              placeholder="Ex: 5 ans dans les mines"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
            dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.experience ? 'input-error' : ''}`}
              value={experience}
              onChange={(e) => setProffessionalExperience(e.target.value)}
            />
            {errors.experience && <p className="input-error-projectSummary text-red-700">{errors.experience}</p>}
          </div>
        </div>

        {/* Proffession */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Proffession
          </label>
          <div className="mt-1">
            <input
              id="proffession"
              type="text"
              name="proffession"
              placeholder="Ex: Journaliste"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
            dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={proffession}
              onChange={(e) => setProfession(e.target.value)}
            />
            {errors.proffession && <p className="input-error-projectSummary text-red-700">{errors.proffession}</p>}
          </div>
        </div>

        {/* Project idea */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            {'Idée d\'investissement'}
          </label>
          <div className="mt-1">
            <input
              id=""
              type="text"
              name="type_of_investment"
              placeholder="cliquez ici pour écrire ..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
            dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={idea}
              onChange={(e) => setIdea(e.target.value)}
            />
            {errors.idea && <p className="input-error-projectSummary text-red-700">{errors.idea}</p>}
          </div>
        </div>

        {/* L'impact de l'idée  */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            {'Impact de l\'idée d\'investissement'}
          </label>
          <div className="mt-1">
            <input
              id=""
              type="text"
              name="impact of idea"
              placeholder="cliquez ici pour écrire ..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 ${errors.email ? 'input-error' : ''}`}
              value={impactOfIdea}
              onChange={(e) => setImpactOfIdea(e.target.value)}
            />
            {errors.impactOfIdea && <p className="input-error-projectSummary text-red-700">{errors.impactOfIdea}</p>}
          </div>
        </div>

        {/* Sector of investment */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            {'Secteur d\'investissement'}
          </label>
          <div className="mt-1">

            <Select
              options={options}
              placeholder="Choisissez un secteur ..."
              value={sectorOfInvestment}
              onChange={handleSelect}
              isSearchable
            />

            {errors.sectorOfInvestment && <p className="input-error-projectSummary text-red-700">{errors.sectorOfInvestment}</p>}
          </div>
        </div>

        {/* Province of interest */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Province interressée
          </label>
          <div className="mt-1">

            <Select
              options={optionList}
              placeholder="Selectionnez une ou plusieurs ..."
              value={provinceOfInterest}
              onChange={handleMultiSelect}
              isSearchable
              isMulti
            />
            {errors.provinceOfInterest && <p className="input-error-projectSummary text-red-700">{errors.provinceOfInterest}</p>}
          </div>
        </div>

        {/* Commentaire */}
        <div>
          <label htmlFor="projectSummary" className="block text-sm font-medium">
            resume du projet
          </label>
          <div className="mt-1">
            <textarea
              id="projectSummary"
              name="projectSummary"
              rows="4"
              className={`input-field block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300
            focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
             dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.projectSummary ? 'input-error' : ''}`}
              value={projectSummary}
              onChange={(e) => setProjectSummary(e.target.value)}
              placeholder="cliquez ici pour écrire..."
            />
            {errors.projectSummary && <p className="input-error-projectSummary text-red-700">{errors.projectSummary}</p>}
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300
         font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default ParticipantForm;
