/* eslint-disable max-len */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import Navbar from '../components/NavBar';

const Faqs = () => (
  <div>
    <Navbar />
    <section className="bg-gray-800 text-gray-100">
      <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
        <p className="p-2 text-sm font-medium tracki text-center  uppercase">Comment Ca marche</p>
        <p className="p-2 text-sm font-medium tracki text-center">Parmi les questions frequemment posees dans le cadre de la conference internationale sur le PDL 145 T, figurant:</p>
        <h2 className="mb-12 text-4xl font-bold leadi text-center md:text-left sm:text-5xl">FaQs</h2>
        <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700">
          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline  text-gray-900">Qui est l’organisateur de cette conference? </summary>
            <div className="px-4 pb-4">
              <p>
                En effet, cette conference est organisee par les congolais de la Diaspora, des associations
                francaises et australiennes en partenariat avec la Presidence et le gouvernement de la RDC.
                Le projet de cette conference a ete initie par Eric Mwamba, un journaliste globetrotter d’origine de la RDC.
              </p>
            </div>
          </details>

          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline text-gray-900">Pourquoi la ville de Rennes?</summary>
            <div className="px-4 pb-4">
              <p>
                La ville de Rennes est la capitale de la Bretagne, une region de l’Ouest de la France qui se distingue par un modele economique qui fait du developpement local son cheval de bataille.
                Elle figure en position privilegiee comme principale fournisseur des produits vivriers de premiere consummation en France et en Europe.
              </p>
            </div>
          </details>

          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline text-gray-900">Qui finance cette conference?</summary>
            <div className="px-4 pb-4 space-y-2">
              <p>La conference est finance par les organisateurs. </p>
            </div>
          </details>

          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline text-gray-900">Quel est le rapport entre les organisateurs et l’Etat Congolais? </summary>
            <div className="px-4 pb-4 space-y-2">
              <p>Les deux parties entretiennent les rapports de complementarite.</p>
            </div>
          </details>
        </div>
      </div>
    </section>
  </div>
);

export default Faqs;
