/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import {
  faPhone,
  faBullhorn,
  faBedPulse,
  faAward,
  faPersonCircleQuestion,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import NavBar from '../components/NavBar';
import CountDown from '../components/CountDown';
import awards from '../images/cards/awards.jpg';
import contact from '../images/cards/contact.jpg';
import events from '../images/cards/events.jpg';
import faqs from '../images/cards/faqs.jpg';
import hotels from '../images/cards/hotels.jpg';
import news from '../images/cards/news.jpg';
import SponsorsLogo from '../components/SponsorsLogo';
import SeeMoreButton from '../components/SeeMoreRennes';
import ShowMoreButton from '../components/SeeMoreAbout';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
// import HeaderPicture from '../images/hero.png';
import bullents from '../images/bullents.png';
import IconEMAIL from '../images/icon_email.gif';

const Acueil = () => (
  <div id="masthead" className="sm:w-[100%] md:w-[90%] lg:w-[90%]" style={{ height: '134px' }}>
    <Header />
    <NavBar />
    <div id="container" className="sm:w-[100%] md:w-[90%] lg:w-[90%]">
      <div className="flex flex-col justify-center px-10 py-3 mx-auto my-1 text-center text-white bg-orange-500 border-t-2 border-gray-800 md:mx-auto md:text-center md:justify-center md:flex-row align-center">
        <h2 className="flex flex-col justify-center w-full text-lg md:w-1/2 h- md:text-sm">
          Compte à rebours de la #CID 145 TERRITOIRES edition 2.
          <span className="hidden md:inline">
            République Démocratique du Congo, RDC
          </span>
        </h2>
        <CountDown />
      </div>

      <div>
        <div className="event-container">
          <div className="event-content">
            <h1 className="event-title">
              ENREGISTREZ-VOUS!
              <br />
              SAVE THE DATE
            </h1>
            <h2 className="event-subtitle">
              PLACE AU SAVOIR
              <span className="highlight-year">2025</span>
            </h2>
            <p className="event-location">Melbourne, Australie</p>
            <p className="event-date"><span className="highlight-date">13-15 March</span></p>
            <a href="https://www.rdc145territoires.com" className="event-link">www.rdc145territoires.com</a>
          </div>
        </div>

        <div className="mt-4">
          <h2 className="text-xl h2-custom ">
            Collaboration, Innovation et Résilience : Promouvoir une Génération Numérique
          </h2>
        </div>
        <div className="mt-4">
          <p className="text-custom">
            {`Rejoignez-nous à "Place au Savoir 2025" à Melbourne,
            Australie, du 13 au 15 mars, pour promouvoir la Collaboration,
            l'Innovation et la Résilience au service d'une génération numérique.
            Organisé par APPLX, le gournemenent Congolais et le Conseil Géospatial d'Australie,
            cet événement invite les Congolais de la diaspora et du pays à explorer
            de nouvelles opportunités et initiatives pour le développement des 145 territoires de la RDC.
            Inscrivez-vous dès maintenant sur`}
            <a href="www.rdc145territoires.com/ParticipantFees" className="ml-3 text-blue-500 underline">www.rdc145territoires.com</a>
            {' '}
            !
          </p>
        </div>
        {/* text div */}
        <div className="mt-4">
          <ul className="pl-8 leading-6 text-left list-disc list-inside">
            <li>Conference days: 13 - 15 Mars</li>
            <li>CID General Assembly: 6 and 12 Mars</li>
            <li>Pre-events: 10 Mars</li>
          </ul>
        </div>
        <div className="mt-4">
          {' '}
          <p className="text-custom">
            {`Une organisation australienne dirige par Eric Mwamba,
          en partenariat avec la diaspora congolaise et des amis du Congo,
          ont uni les  efforts pour mobiliser des investissements vers les 145 territoires de la RDC.
          Nous organisons une conférence à Rennes en octobre 2023,
          axée sur le développement local sous le concept "EKOLO MAKASI",
          visant à diversifier l'économie congolaise à travers l'agro-industrie, la pêche,
          le tourisme et la formation.`}
          </p>
        </div>
      </div>

      <div
        className="bg-gray-800  h-[80vh] bg-cover bg-center text-white flex justify-center align-center text-center py-10"
        style={{
          backgroundImage:
            // 'url("https://ik.imagekit.io/pamphilemkpchill/forestHigh.gif?updatedAt=1688374530126")',
            'url("https://cdn.pixabay.com/photo/2018/03/02/19/21/nature-3194001_640.jpg")',
          backgroundSize: 'cover',
        }}
      >
        <div className="flex flex-col justify-center gap-1 px-3 py-10 align-center md:justify-between sm:px-10 md:px-5">
          <div className="flex md:justify-center">
            <span className="text-4xl text-bold md:text-5xl">
              Melbourne, Australie du 13 au 15 Mars 2025
            </span>
          </div>
          <div className="flex md:justify-center">
            <span className="text-2xl text-yellow-500 text-bold md:text-3xl">
              RDC, EKOLO MAKASI
            </span>
          </div>

          <div className="flex justify-center text-lg text-center align-center md:text-2xl">
            <span className="text-justify md:w-3/4">
              CONFERENCE INTERNATIONALE SUR LE DEVELOPPEMENT LOCAL EN RDC
            </span>
          </div>
          <div className="flex justify-center align-center sm:text-center">
            <p className="text-sm text-justify lowercase md:w-3/4 text-bold">
              {` La combinaison de deux mots lingala, EKOLO, qui signifie pays et
            MAKASI, fort, ça veut dire un pays fort. Il s’agit d’une
            révolution qui met ensemble les Congolais de la diaspora et ceux
            du pays pour développer leur pays à travers l’entrepreneuriat et
            le tiers secteur.`}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-10 px-5 py-5 mx-3 mt-20 text-black md:grid-cols-2 lg:grid-cols-3 md:mx-5 lg:mx-20 md:gap-10 lg:gap-3">
        {/* cards */}
        <Link
          to="/SpecialEvents"
          className="bg-white bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
          style={{
            backgroundImage: `url(${events})`,
            backgroundSize: 'cover',
          }}
        >
          <div className="flex flex-col justify-center py-5 text-center text-white bg-gray-800 bg-opacity-10 align-center backdrop-filter backdrop-blur-lg bg-blend-darken">
            <FontAwesomeIcon
              icon={faUsers}
              className="w-[50px] h-[50px] text-center self-center"
            />
            <span className="text-lg text-bold">Special Evenements</span>
          </div>
        </Link>

        <Link
          to="/Awards"
          className="bg-black-800 bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
          style={{ backgroundImage: `url(${awards})`, backgroundSize: 'cover' }}
        >
          <div className="flex flex-col justify-center py-5 text-center text-white bg-gray-800 bg-opacity-30 align-center backdrop-filter backdrop-blur-lg bg-blend-darken">
            <FontAwesomeIcon
              icon={faAward}
              className="w-[50px] h-[50px] text-center self-center "
            />
            <span className="text-lg text-bold">Awards</span>
          </div>
        </Link>

        <Link
          to="/Hotels"
          className="bg-black-800 bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
          style={{ backgroundImage: `url(${hotels})`, backgroundSize: 'cover' }}
        >
          <div className="flex flex-col justify-center py-5 text-center text-white bg-gray-800 bg-opacity-30 align-center backdrop-filter backdrop-blur-lg bg-blend-darken">
            <FontAwesomeIcon
              icon={faBedPulse}
              className="w-[50px] h-[50px] text-center self-center "
            />
            <span className="text-lg text-bold">Hotels</span>
          </div>
        </Link>

        <Link
          to="/Faqs"
          className="bg-black-800 bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
          style={{ backgroundImage: `url(${faqs})`, backgroundSize: 'cover' }}
        >
          <div className="flex flex-col justify-center py-5 text-center text-white bg-gray-800 bg-opacity-30 align-center backdrop-filter backdrop-blur-lg bg-blend-darken">
            <FontAwesomeIcon
              icon={faPersonCircleQuestion}
              className="w-[50px] h-[50px] text-center self-center "
            />
            <span className="text-lg text-bold">FaQs</span>
          </div>
        </Link>

        <Link
          to="/News"
          className="bg-black-800 bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
          style={{ backgroundImage: `url(${news})`, backgroundSize: 'cover' }}
        >
          <div className="flex flex-col justify-center py-5 text-center text-white bg-gray-800 bg-opacity-10 align-center backdrop-filter backdrop-blur-lg bg-blend-darken">
            <FontAwesomeIcon
              icon={faBullhorn}
              className="w-[50px] h-[50px] text-center self-center "
            />
            <span className="text-lg text-bold">News</span>
          </div>
        </Link>

        <Link
          to="/Contact"
          className="bg-black-800 bg-cover bg-center flex flex-col justify-center align-center text-center h-[300px] gap-5"
          style={{
            backgroundImage: `url(${contact})`,
            backgroundSize: 'cover',
          }}
        >
          <div className="flex flex-col justify-center py-5 text-center text-white bg-gray-800 bg-opacity-50 align-center backdrop-filter backdrop-blur-lg bg-blend-darken">
            <FontAwesomeIcon
              icon={faPhone}
              className="w-[50px] h-[50px] text-center self-center "
            />
            <span className="text-lg text-bold">Contact</span>
          </div>
        </Link>
      </div>

      <div className="flex w-[100%] gap-2 items-center justify-center ">
        <div className="w-[255px] h-[102px] background-color">
          <h3 className="mt-8 whatsnew-text">DU NOUVEAU?</h3>
        </div>
        <div className="w-[496.328px] h-[102px] whatnew-backgrounnd flex flex-col items-left pl-2 justify-center ">
          <div className="flex items-center gap-1">
            <img src={bullents} alt="bullent" />
            <a href="@" className="link-red">
              {' '}
              APPLX directeur explique le theme
            </a>
          </div>
          <div className="flex items-center gap-1 mt-4">
            <img src={bullents} alt="bullents" />
            <a href="@" className="link-red">
              Meeting de pareparation en Australie
            </a>
          </div>
        </div>
      </div>
      <hr />

      <div>
        <div className="">
          <h3 className="expect-heading">{'A  QUOI S\'ATTENDRE'}</h3>
        </div>
        <div>
          <p className="px-5 pb-5 text-base font-bold leading-8 text-left paragraph-expect">
            {`L'un des objectifs de cette initiative
           c'est le réseautage, la force de l'association
            et de la mise en commun des efforts. Plusieurs experts
             et acteurs Australiens, Canadiens et Africains vont partager
              leurs experiences avec les participants venus de la RDC. En plus,
               il y aura plusieurs visites de terrain et des ententes des partenariats. `}
          </p>
        </div>
      </div>

      <hr />

      <div className="w-[97%] ml-auto mr-auto">
        <div>
          <h3 className="mt-4 attend-heading">POURQUOI PARTICIPER?</h3>
        </div>
        <div className="flex flex-col text-xl md:text-base lg:text-base md:flex-row lg:flex-row gap-2 mt-[25px] p-3">
          <div className="flex flex-col gap-6">
            {' '}
            <p className="attend-para">Opportunités de Collaboration Internationale</p>
            <p className="text-left text-wrap">
              {`La conférence réunit des acteurs de la diaspora congolaise et des amis du Congo,
             ainsi que des experts internationaux, offrant des opportunités uniques de collaboration
              et de réseautage à l'échelle mondiale.
            En collaborant avec diverses parties prenantes,
             nous pouvons renforcer nos efforts pour développer 
             les 145 territoires de la RDC de manière inclusive et durable.
              `}
            </p>
          </div>
          <div className="flex flex-col gap-6 ">
            <p className="attend-para">Innovation pour le Développement Durable</p>
            <p className="text-left text-wrap">
              {`Explorez des solutions innovantes dans des secteurs clés tels que l'agro-industrie,
              la pêche, le tourisme et la formation, pour diversifier l'économie congolaise et
              créer des emplois locaux.
              La conférence met en lumière les technologies et les pratiques innovantes qui
              peuvent transformer positivement les communautés locales et renforcer leur résilience
              face aux défis économiques et environnementaux.`}
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <p className="attend-para">Renforcement de la Résilience Économique et Sociale</p>
            <p className="text-left text-wrap">
              {`Apprenez comment les initiatives locales et
              internationales peuvent renforcer la résilience
              économique et sociale des territoires congolais,
              en mettant l'accent sur l'autonomisation
              des communautés et le développement durable.
              Partagez des expériences et des meilleures
              pratiques pour soutenir une croissance économique
              inclusive et durable, en favorisant une résilience
              accrue face aux chocs économiques et climatiques.`}
            </p>
          </div>
          <div className="flex flex-col gap-6 ">
            <p className="attend-para ">  Soutien à une Génération Numérique en Plein Essor</p>
            <p className="text-left text-wrap">
              {`Explorez comment les avancées numériques peuvent catalyser le développement des territoires,
               en améliorant l'accès aux services essentiels, à l'éducation et aux opportunités économiques.
                Engagez-vous dans des discussions sur l'importance de l'innovation technologique pour créer un avenir numérique
                inclusif et équitable pour les jeunes Congolais.`}
              {' '}
            </p>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <div>
          <h3 className="mt-4 attend-heading">DETAILS</h3>
        </div>
        <div className="w-[97%] ml-auto mr-auto flex flex-col justify-center  items-center md:flex-row lg:flex-row gap-2 mt-[25px]">
          <div className="w-[253.63px] h-[186px] card-color flex flex-col items-center justify-center gap-4 ">
            <p className="card-text"> QUAND:</p>
            <p className="card-text">13 - 15 Mars 2025</p>
          </div>
          <div className="w-[253.63px] h-[186px] card-color flex flex-col items-center justify-center gap-4">
            <p className="card-text">OU</p>
            <p className="card-text-2">Melbourne, AUSTRALIA</p>
          </div>
          <div className="w-[253.63px] h-[186px] card-color flex flex-col items-center justify-center gap-4">
            <p className="card-text">Contact</p>
            <img src={IconEMAIL} alt="email" />
            <p href="@" className="mt-[-10px] link-text-2">Contact Us</p>
          </div>
        </div>
      </div>

      <div className="mx-3 mt-5 mb-10 text-white rounded bg-sky-900">
        <div className="flex flex-col justify-center mx-3 text-white align-center sm:px-5">
          <p className="my-5 text-4xl text-center text-yellow-500 text-bold lg:text-left lg:mx-20 ">
            A propos
          </p>
          <ShowMoreButton />
        </div>
      </div>

      <div className="mx-3 mt-5 mb-10 text-white rounded bg-sky-900 ">
        <div className="flex flex-col justify-center mx-3 align-center sm:px-5">
          <p className="my-5 text-4xl text-center text-yellow-500 text-bold lg:text-left lg:mx-20">
            Le choix de Melbourne, Australie
          </p>
          <SeeMoreButton />
        </div>
      </div>

      <div className="container p-4 mx-auto">
        <h1 className="mb-4 text-2xl font-semibold">
          Pour le budget participatif
        </h1>

        {/* <!-- YouTube Video Embed Code --> */}
        <div className="w-full aspect-w-16 aspect-h-9">
          {/* <iframe class="w-full h-full" src="https://www.youtube.com/embed/YOUR_VIDEO_ID" frameborder="0" allowfullscreen></iframe> */}
          <iframe
            height="480"
            src="https://www.youtube.com/embed/gw35Vov64MU"
            className="w-full aspect-w-16 aspect-h-9"
            title="POLITIQUE PUBLIQUE INCLUSIVE: LE BUDGET PARTICIPATIF"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </div>
      </div>

      <SponsorsLogo />

      <div className="flex pt-10 pb-3 pl-3 mt-4 text-sm bg-gray-800 border-t border-gray-500 sm:pl-10 sm:text-md text-bold">
        <p className="flex text-white">
          ©2024 RDC, 145 TERRITOIRES. All rights reserved.
          {' '}
          <a href="https://github.com/Pamphilemkp" className="text-white">
            {' '}
            by Pamphile MKP
          </a>
        </p>
      </div>
      <ToastContainer />
    </div>
  </div>
);

export default Acueil;
