/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import PageError from '../images/404.svg';
import NavBar from '../components/NavBar';

const PageNotFound = () => (
  <div className="">
    <NavBar />
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12">
        <h1 className="mb-4 text-4xl font-bold tracking-tight leading-none text-gray-900 lg:mb-6 md:text-5xl xl:text-6xl dark:text-white">Page Not Found</h1>
        <img src={PageError} className="w-2/3 h-2/3 mx-auto" alt="Page Not Found" />
        <p className="font-light text-gray-500 md:text-lg xl:text-xl dark:text-gray-400">{'We don\'t support this url yet.'}</p>
        <p className="font-light text-gray-800 text-bold md:text-xl xl:text-xl dark:text-gray-400">Coming soon.</p>
      </div>
    </section>
  </div>
);

export default PageNotFound;
