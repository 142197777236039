import React from 'react';
import PaymentFees from '../components/PaymentFees';
import NavBar from '../components/NavBar';

const ParticipantFees = () => (
  <div>
    <NavBar />
    <PaymentFees />
  </div>
);

export default ParticipantFees;
