import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// actions
const ADD_PARTICIPANT = 'participant/Store/ADD_PARTICIPANT';
const GET_PARTICIPANT = 'participant/Store/GET_PARTICIPANT';

const initialState = [];

const Api = 'https://www.rdc145territoires.me/participants';

// bypassing the devise auth from the frontend api call
const deviseBypasser = {
  'X-Frontend-Request': 'true',
};

// reducer

const participanReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_PARTICIPANT}/fulfilled`:
      action.payload.map((data) => state.push(data));
      // console.log(action.payload);
      return action.payload;

    case `${ADD_PARTICIPANT}/fulfilled`:
      return [...state, action.payload];

    default:
      return state;
  }
};

// Action creators

// GET
export const getParticipants = createAsyncThunk(GET_PARTICIPANT, async (payload) => {
  await axios.get(Api, { withCredentials: true });
  return payload;
});

// POST
export const addParticipants = createAsyncThunk(ADD_PARTICIPANT, async (payload) => {
  await axios.post(Api, payload, { withCredentials: true, headers: deviseBypasser });
  return payload;
});

export default participanReducer;
