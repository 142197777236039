import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// actions
const ADD_SPONSOR = 'sponsor/Store/ADD_SPONSOR';
const GET_SPONSOR = 'sponsor/Store/GET_SPONSOR';

const initialState = [];

const Api = 'https://www.rdc145territoires.me/sponsors';

// bypassing the devise auth from the frontend api call
const deviseBypasser = {
  'X-Frontend-Request': 'true',
};

// reducer

const sponsorsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_SPONSOR}/fulfilled`:
      action.payload.map((data) => state.push(data));
      // console.log(action.payload);
      return action.payload;

    case `${ADD_SPONSOR}/fulfilled`:
      return [...state, action.payload];

    default:
      return state;
  }
};

// Action creators

// GET
export const getSponsors = createAsyncThunk(GET_SPONSOR, async (payload) => {
  await axios.get(Api, { withCredentials: true });
  return payload;
});

// POST
export const addSponsors = createAsyncThunk(ADD_SPONSOR, async (payload) => {
  await axios.post(Api, payload, { withCredentials: true, headers: deviseBypasser });
  return payload;
});

export default sponsorsReducer;
