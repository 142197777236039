import React from 'react';
import NavBar from '../components/NavBar';
import ProgrammeButton from '../components/ProgrammeButton';

const Programme = () => (
  <div>
    <NavBar />
    <ProgrammeButton />
  </div>
);

export default Programme;
