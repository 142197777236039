/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

const getRemainingTime = (targetDate) => {
  const now = new Date().getTime();
  const distance = targetDate.getTime() - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return {
    days, hours, minutes, seconds,
  };
};

const CountDown = () => {
  const targetDate = new Date('2025-03-06T08:00:00+01:00'); // Replace with your target date
  const [remainingTime, setRemainingTime] = useState(getRemainingTime(targetDate));
  const [countdownOver, setCountdownOver] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const newRemainingTime = getRemainingTime(targetDate);
      if (newRemainingTime.days <= 0 && newRemainingTime.hours <= 0 && newRemainingTime.minutes <= 0 && newRemainingTime.seconds <= 0) {
        setCountdownOver(true);
        clearInterval(interval); // Stop the countdown when it reaches zero
      } else {
        setRemainingTime(newRemainingTime);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  return (
    <div className=" w-full md:w-1/2 flex align-center justify-center text-center gap-4 text-sm">
      {countdownOver ? (
        <p>{'It\'s time the conference is taking place!'}</p>
      ) : (
        <>
          <div className="flex flex-col justify-center align-center">
            <span className=" p-3 bg-orange-400 rounded-full text-bold">{remainingTime.days}</span>
            <span>jours</span>
          </div>
          <p className="flex  justify-center py-3 align-start">:</p>
          <div className="flex flex-col justify-center align-center">
            <span className="p-3 bg-orange-400 rounded-full text-bold">
              {remainingTime.hours}
              {' '}
            </span>
            <span>heures </span>
          </div>
          <p className="flex  justify-center py-3 align-start">:</p>
          <div className="flex flex-col justify-center align-center">
            <span className="p-3 bg-orange-400 rounded-full text-bold">{remainingTime.minutes}</span>
            <span>minutes</span>
          </div>
          <p className="flex  justify-center py-3 align-start">:</p>
          <div className="flex flex-col justify-center align-center">
            <span className="p-3 bg-orange-400 rounded-full text-bold">{remainingTime.seconds}</span>
            <span>secondes</span>
          </div>
        </>
      )}
    </div>
  );
};

export default CountDown;
