import React from 'react';
import SpeakerForm from '../components/SpeakerForm';
import NavBar from '../components/NavBar';

const SpeakerSignUp = () => (
  <div>
    <NavBar />
    <SpeakerForm />
  </div>
);

export default SpeakerSignUp;
