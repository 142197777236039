import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import NavBar from '../components/NavBar';
import SponsorForm from '../components/SponsorForm';

const Sponsor = () => {
  const notify = () => toast.success('Votre payment a été éfféctué, veuillez compléter ce formulaire!', {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });

  useEffect(() => {
    notify();
  }, []);

  return (
    <div>
      <NavBar />
      <SponsorForm />
      <ToastContainer />
    </div>
  );
};

export default Sponsor;
