/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';

const Enregistrement = () => (
  <div>
    <NavBar />
    <div className="flex flex-col gap-3 text-jusify text-md  lg:mx-20 lg:text-md pb-10 pt-5 px-7 sm:px-1">
      <div>
        <p>Reservez votre ticket pour une participation ordinaire ou en tant que sponsor à la conférence de Rennes du 28 au 30 Octobre 2023, veuillez cliquer sur le lien ci-dessous</p>
        <Link to="/ParticipantFees" className="text-blue-600"> Faites votre enregistement ici</Link>
      </div>

      <div>
        <p>
          Devenez ambassadeur/ambassadrice d’un des 145 territoires de la République Démocratique du Congo.
          <br />
          Veuillez cliquer sur le lien ci-dessous pour compléter le formulaire *
        </p>
        <Link to="/Ambassador" className="text-blue-600">{'Formulaire d\'enregistrement pour ambassadeur'}</Link>
      </div>

      <div>
        <p>
          Devenez un speaker (aurateur/auratrice) à la conférence PDL des 145 territoires à Rennes du 28 au 30 Octobre 2023.
          <br />
          Veuillez cliquer sur le lien ci-dessous pour compléter le formulaire *
        </p>
        <Link to="/speaker-sign-up" className="text-blue-600">{'Formulaire d\'enregistrement pour speaker'}</Link>
      </div>

    </div>
  </div>
);

export default Enregistrement;
