import React from 'react';
import { Link } from 'react-router-dom';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Gallery = () => (

  <section className="py-6 bg-gray-800 text-gray-50">
    <Link to="/" className="mx-20">
      <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-5" />
      <span>Retour</span>
    </Link>

    <div className="container grid grid-cols-2 gap-4 p-4 mx-auto md:grid-cols-4">

      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/IMG_2542.jpeg?updatedAt=1696262534190" alt="Nuit du festival talents" />
      <img alt="Ministre Jean Guy and Mde Dominique" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/IMG_2748.jpeg?updatedAt=1696262530313" />
      <img alt="Soiree talent" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/IMG_2971.jpeg?updatedAt=1696262529201" />
      <img alt="Eric Mwamba, Jean Guy and Dominique Nsamba" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/IMG_2750.jpeg?updatedAt=1696262528110" />
      <img src="https://ik.imagekit.io/pamphilechill/new-updates%20images/995b35e9-5111-4ee8-93a0-ecd442adf294.jpeg?updatedAt=1696262455754" alt="Jour Festival" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/b291b335-e947-4b15-956c-d43d6976dc0f.jpeg?updatedAt=1696262455133" alt="Mde Dominique montrant le paysage de la sale" />
      <img alt="Mde Dominique " className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at14.44.23.jpg?updatedAt=1695128611934" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" alt="super frestival" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/IMG_2973.jpeg?updatedAt=1696262450571" />
      <img alt="Madame Dominique espace 2023" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/e7d1ce4c-1359-46db-970d-e0ec7fba9678.jpeg?updatedAt=1696262439461" />
      <img src="https://ik.imagekit.io/pamphilechill/new-updates%20images/3a4dbb76-fc18-4433-bfe3-a0b510fd1492.jpeg?updatedAt=1696262439039" alt="Mde Dominique sur le tracteur" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/2358575e-f10a-4347-a32f-ca0135d224d8.jpeg?updatedAt=1696262438766" alt="journee festival" />
      <img alt="petite causerie au festival" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/3fce5c4d-199f-4cb2-b695-2ae05a9b84bc.jpeg?updatedAt=1696262438683" />
      <img alt="shiny smiling faces" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/e7fc7d4e-3a8c-4600-82fd-e8035902cd4e.jpeg?updatedAt=1696262438563" />
      <img alt="festival espace 2023" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/new-updates%20images/7c22a225-cec4-472b-957a-d0af2417a0ad.jpeg?updatedAt=1696262437965" />
      <img src="https://ik.imagekit.io/pamphilechill/new-updates%20images/915fe389-9ac2-4f97-b859-3853dcfa4477.jpeg?updatedAt=1696262436072" alt="super croisade" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />
      <img src="https://ik.imagekit.io/pamphilechill/new-updates%20images/96478f3d-ef2e-45a5-971e-a4f89e4fccbc.jpeg?updatedAt=1696262435734" alt="festival espace 2023 madame Dominique avec une participante" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />

      {/* this is the last line of the new updates */}

      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsAppmage%202023-09-18%20at%2014.44.23.jpg?updatedAt=1695128612263" alt="Image5" />
      <img alt="imgkit2" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at14.44.23.jpg?updatedAt=1695128611934" />
      <img src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.02.48v.jpg?updatedAt=1695128608497" alt="imgkit5" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.02.48d.jpg?updatedAt=1695128606234" alt="Image6" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.02.48ccx.jpg?updatedAt=1695128606456" alt="Image7" />
      <img alt="imgkit5" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.02.48cds.jpg?updatedAt=1695128605783" />
      <img alt="imgkit6" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.02.482.jpg?updatedAt=1695128603090" />
      <img alt="imgkit7" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.02.48.jpg?updatedAt=1695128600177" />

      <img alt="imgkit8" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.02.48%20%20cdd.jpg?updatedAt=1695128597473" />
      <img alt="imgkit9" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.00.16.jpg?updatedAt=1695128595825" />

      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.00.18.jpg?updatedAt=1695128594706" alt="Image8" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.00.17.jpg?updatedAt=1695128594479" alt="Image9" />
      <img alt="imgkit11" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2015.00.15.jpg?updatedAt=1695128591876" />
      <img alt="imgkit12" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2014.57.40.jpg?updatedAt=1695128589333" />
      <img alt="imgkit13" src="https://ik.imagekit.io/pamphilechill/drc-145t-WAPictures/WhatsApp%20Image%202023-09-18%20at%2014.57.34.jpg?updatedAt=1695128588268" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />

      {/* Meeting screenshots */}

      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.42.jpg?updatedAt=1695240275768" alt="Image81" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.46ffdffffffffffffffffffffffff.jpg?updatedAt=1695240276237" alt="Image91" />
      <img alt="imgkit111" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.45f.jpg?updatedAt=1695240276264" />
      <img alt="imgkit121" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23vf.jpg?updatedAt=1695240323078" />
      <img alt="imgkit131" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23frfr.jpg?updatedAt=1695240300491" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23sr.jpg?updatedAt=1695240321775" alt="Image81" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23.jpg?updatedAt=1695240298766" alt="Image91" />
      <img alt="imgkit111" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23eeefwerf.jpg?updatedAt=1695240298603" />
      <img alt="imgkit121" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23eeefwerf.jpg?updatedAt=1695240298603" />
      <img alt="imgkit131" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23cc.jpg?updatedAt=1695240298538" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23eea.jpg?updatedAt=1695240298376" alt="Image81" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23e.jpg?updatedAt=1695240298237" alt="Image91" />
      <img alt="imgkit111" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23eee.jpg?updatedAt=1695240298233" />
      <img alt="imgkit112" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23ds.jpg?updatedAt=1695240298154" />
      <img alt="imgkit131" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23c.jpg?updatedAt=1695240297981" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23ewe.jpg?updatedAt=1695240297608" alt="Image81" />
      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.2.jpg?updatedAt=1695240287768" alt="Image91" />
      <img alt="imgkit111" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.23xx.jpg?updatedAt=1695240277337" />
      <img alt="imgkit121" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.43df.jpg?updatedAt=1695240276972" />
      <img alt="imgkit131" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.4.23.jpg?updatedAt=1695240276908" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />

      <img className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.45.jpg?updatedAt=1695240276883" alt="Image91646" />
      <img alt="imgkit111235" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.44e.jpg?updatedAt=1695240276590" />
      <img alt="imgkit1211" className="w-full h-full rounded shadow-sm min-h-48 bg-gray-500 aspect-square" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.42pp.jpg?updatedAt=1695240276448" />
      <img alt="imgkit1310" src="https://ik.imagekit.io/pamphilechill/remaining%20pics%20DRC%20145T/WhatsApp%20Image%202023-09-18%20at%2014.44.47.jpg?updatedAt=1695240276261" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-1 md:row-start-3 bg-gray-500 aspect-square" />

    </div>
  </section>

);

export default Gallery;
