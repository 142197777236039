/* eslint-disable react/button-has-type */
import React, { useState } from 'react';

const SeeMoreButton = () => {
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  return (
    <div>
      <div className="flex flex-col gap-3  text-justify text-md lg:mx-20 lg:text-md pt-5">
        {/* Your initial content here */}
        <p>
          {`
          Plusieurs raisons ont milité pour le choix de Melbourne comme prochaine destination
          d’expérience pour notre rendez-vous. En voici quelques-unes :
          `}
        </p>
        <h2 className="font-bold text-md">
          {`1. Interconnexion réussie entre ses 26 millions d’habitants
           et un territoire très vaste`}
        </h2>
        <p>
          {`
          Avec 7 millions de km², l’Australie est trois fois plus vaste que la République Démocratique
          du Congo. Du point de vue géo-économique de la RDC, la vaste étendue du territoire national
          pose la problématique du contrôle des frontières avec neuf autres pays voisins et du
          déploiement de l'autorité de l’État partout. En participant à la conférence de Melbourne,
          les responsables congolais pourront s’inspirer à bien des égards.`}
        </p>
        <br />
      </div>
      {showMore && (
        <div className="flex flex-col text-justify  text-md lg:mx-20 lg:text-md">
          {/* Additional content here */}

          <p>
            {`
           En effet, bien que l’Australie s’étende sur un territoire très grand, elle gère ses frontières
           à merveille. À tel point que les décideurs européens se proposent de s’en inspirer pour le
           contrôle de leurs frontières poreuses. Par ailleurs, l’Australie s’est dotée d’infrastructures
           solides et impressionnantes qui relient tout son territoire : routes, ponts, rails, plusieurs
           aéroports, bateaux, navires...
          `}
          </p>
          <br />
          <h2 className="font-bold text-md">2. Un modèle de développement bien réussi à la base</h2>
          <br />
          <p>
            {`
            L’Australie organise trois niveaux de pouvoirs décentralisés : local, provincial et fédéral
            national, bien réussi sur le plan économique et financier. Par exemple, dans le domaine de
            l’éducation, les garderies et les écoles maternelles sont financées. En ce qui concerne la
            construction d’infrastructures, leurs frais de fonctionnement et de maintenance sont pris
            en charge par le pouvoir local (l’équivalent du niveau territorial en RDC). Il en va de
            même pour les bibliothèques, les centres communautaires et les parcs d’attractions.
            Tandis que les écoles primaires et secondaires sont financées par le gouvernement
            provincial, et enfin les universités par l’État fédéral national. Dans la même optique,
            les petites et moyennes entreprises bénéficient de différentes facilités et 
            de différents types de financements selon le cas, à tous les trois niveaux de pouvoir.
            Les délégués à la conférence vont partager cette expérience avec les acteurs
            en Australie. Ils vont également appréhender les réalités lors des visites de 
            travail sur le terrain.
            `}
          </p>
          <br />
          <h2 className="font-bold text-md">3. Administration 100% numérisée</h2>
          <br />
          <p>
            {`
            Bien avant la COVID-19, l’Australie était déjà championne du digitalisme au point que la
            pandémie ne lui a causé aucun choc. Au contraire, le pays s’est solidifié avec ce que
            certains spécialistes ont qualifié d'économie COVID-19 (travail à distance ou télétravail).
            La RDC pourrait tirer profit du partenariat avec l’Australie, car la plupart des
            entreprises australiennes emploient une main-d'œuvre importante à l’étranger. Elles
            offrent une chance aux habitants de travailler pour les entreprises basées en Australie,
            d’avoir un emploi et d'être bien rémunérés. Les délégués venus de la RDC pourraient
            saisir cette opportunité, conclure des partenariats avec des entreprises australiennes
            pour la création de nouveaux emplois à leur retour en RDC.
          `}
          </p>
          <br />
          <h2 className="font-bold text-md">4. Patriotisme économique</h2>
          <br />
          <p>
            {`
          L’Australie pratique le patriotisme économique. Dans les surfaces commerciales,
          les citoyens australiens achètent d’abord des produits locaux en soutien à l’économie
          du pays.
          `}
          </p>
          <br />

          <h2 className="font-bold text-md">
            {`5. Une économie qui se distingue par la qualité de
          l’industrialisation et des exportations`}
          </h2>
          <br />
          <p>
            {`
          En effet, l’Australie produit et exporte vers de grands marchés : les USA (350 000 000 consommateurs),
          l’UE (400 000 000 consommateurs), la Chine (1 000 000 000 de consommateurs), le Japon, la Nouvelle-Zélande
          et 18 autres pays. C’est une intelligence économique qui exige que l'Australie ne se contente pas de son
          propre marché intérieur, qui est modeste avec seulement 26 millions de consommateurs. Bien qu’elle soit
          très isolée du reste du monde, l'Australie a su s’imposer avec cette économie d’exportation, allant
          jusqu’à fournir à un pays puissant comme le Qatar l’essentiel des produits de base tels que la viande
          de mouton et le blé.
        `}
          </p>
          <br />
          <p>
            {`
          Il est à noter que bien que ces destinations ne soient pas des portes à côté, l'Australie 
          a brisé le mythe de la distance et de l’isolement géographique pour avoir son mot à dire
          au chapitre du commerce international. Lors de cette conférence, la RDC aura à apprendre 
          de ce savoir-faire.
          `}
          </p>
          <br />
          <h2 className="font-bold text-md">6. Franchises</h2>
          <br />
          <p>
            {`
          L’Australie utilise son service postal pour créer plusieurs PME et emplois à travers le pays.
          La circulation des courriers et des colis figure parmi les priorités économiques. La poste
          est l'une des premières entreprises franchisées visibles dans tous les quartiers sur
          l’ensemble du vaste territoire national en Australie.
          `}
          </p>
          <br />
        </div>
      )}

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold my-5 py-2 px-4 rounded lg:mx-20"
        onClick={handleToggle}
      >
        {showMore ? 'Voir Moins' : 'Voir Plus'}
      </button>
    </div>
  );
};

export default SeeMoreButton;
