/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { addSponsors } from '../redux/sponsor/Store';

const SponsorForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [receipt, setReceipt] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [citizenship, setCitizenship] = useState('');
  const [reasonOfSponsorship, setReasonOfSponsorship] = useState('');
  const [typeOfSponsorship, setTypeOfSponsorship] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  // form redirection and redux state access
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notify = () => toast.success('Votre candidature  a été envoyé avec succès', {
    position: 'top-right',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    // for first name

    if (firstName.length === 1 || firstName.length <= 3) {
      formIsValid = false;
      newErrors.firstName = 'Votre nom doit avoir plus 3 lettres';
    }

    if (firstName.trim() === '') {
      formIsValid = false;
      newErrors.firstName = 'Please entrez votre name';
    }

    // for last name

    if (lastName.length === 1 || lastName.length <= 3) {
      formIsValid = false;
      newErrors.lastName = 'Votre nom doit avoir plus 3 lettres';
    }

    if (lastName.trim() === '') {
      formIsValid = false;
      newErrors.firstName = 'Please entrez votre name';
    }

    // for the payment receipt

    if (receipt.length === 1 || receipt.length <= 6) {
      formIsValid = false;
      newErrors.receipt = 'Le numéro du réçu de payment a plus 6 lettres';
    }

    if (receipt.trim() === '') {
      formIsValid = false;
      newErrors.receipt = 'Please entrez le numéro du réçu de payment';
    }

    // for email

    if (!email) {
      formIsValid = false;
      newErrors.email = 'Please entrez votre email';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      newErrors.email = 'Please entrez votre un email valide';
    }

    // for phone number

    if (!isValidPhoneNumber(phone)) {
      formIsValid = false;
      newErrors.phone = 'Please entrez un numero de telephone valide';
    } else if (phone.trim() === '') {
      formIsValid = false;
      newErrors.phone = 'Le numero de telephone est obligatoire';
    }

    // for address

    if (address.length === 1 || address.length <= 15) {
      formIsValid = false;
      newErrors.address = 'Votre adress doit avoir plus 3 mots';
    }

    if (!address) {
      formIsValid = false;
      newErrors.address = 'Please entrez votre adresse';
    }

    // Reason of sponsorship

    if (reasonOfSponsorship.length === 1 || reasonOfSponsorship.length <= 20) {
      formIsValid = false;
      newErrors.reasonOfSponsorship = 'Votre raison pour devenir notre sponsor doit avoir plus 3 mots';
    }

    if (!reasonOfSponsorship) {
      formIsValid = false;
      newErrors.reasonOfSponsorship = 'Please, dites-nous pourquoi vous voulez devenir notre sponsor';
    }

    // Type of sponsorship
    if (typeOfSponsorship.length === 1 || typeOfSponsorship.length <= 3) {
      formIsValid = false;
      newErrors.typeOfSponsorship = 'Votre type de sponsorship doit avoir au moins un mot';
    }

    if (!typeOfSponsorship) {
      formIsValid = false;
      newErrors.typeOfSponsorship = 'Please dites nous quel type de sponsorship vous voulez nous apporter';
    }

    // country of residence
    if (citizenship.length === 1 || citizenship.length <= 3) {
      formIsValid = false;
      newErrors.citizenship = 'Votre pays de residence doit etre correct';
    }

    if (!citizenship) {
      formIsValid = false;
      newErrors.citizenship = 'Please enterez votre pays de residence';
    }

    // for message

    if (message.length === 1 || message.length <= 5) {
      formIsValid = false;
      newErrors.message = 'Votre message doit avoir plus 5 lettres';
    }

    if (!message) {
      formIsValid = false;
      newErrors.message = 'Please entrez votre message';
    }

    setErrors(newErrors);
    return formIsValid;
  };

  // SUBMIT ALL THE DATA FOR PROCESSING IN THE STORE MANAGEMENT IN THIS CASE ADD
  const data = {
    first_name: firstName,
    last_name: lastName,
    receipt,
    email,
    phone,
    address,
    country_of_residence: citizenship,
    reason_of_sponsorship: reasonOfSponsorship,
    type_of_sponsorship_offering: typeOfSponsorship,
    feedback: message,

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Handle form submission if everything is ok send to the server

      navigate('/');
      dispatch(addSponsors(data));
      notify();
    }
  };

  return (
    <div className="shadow-2xl w-5/6 mx-auto p-10 mt-3 bg-gray-100 mb-6 rounded">

      <p className="mb-5 text-lg font-medium">{'S\'il vous plait, Veuillez completer tous les champs de ce formulaire pour que votre candidature soit pris en considération.'}</p>

      <form onSubmit={handleSubmit} className="space-y-6">

        {/* Fisrt Name */}

        <div>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
            Votre Nom
          </label>
          <div className="mt-1">
            <input
              id="name"
              type="text"
              name="firstName"
              placeholder="Ex: Musonda"
              className={`input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
               focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
               dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.firstName ? 'input-error' : ''}`}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {errors.firstName && <p className="input-error-message text-red-700">{errors.firstName}</p>}
          </div>
        </div>

        {/* Last Name */}

        <div>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
            Votre prenom
          </label>
          <div className="mt-1">
            <input
              id="lastName"
              type="text"
              name="lastName"
              placeholder="Ex: Pamphile"
              className={`input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
               focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
               dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.lastName ? 'input-error' : ''}`}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {errors.lastName && <p className="input-error-message text-red-700">{errors.lastName}</p>}
          </div>
        </div>

        {/* Email */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="email"
              name="email"
              placeholder="ex: edmjvnews@gmail.com"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p className="input-error-message text-red-700">{errors.email}</p>}
          </div>
        </div>

        {/* Phone number */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Phone number
          </label>
          <div className="mt-1">
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="FR"
              value={phone}
              onChange={setPhone}
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm
                           rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 `}
            />
            {errors.phone && <p className="input-error-message text-red-700">{errors.phone}</p>}
          </div>
        </div>

        {/* Address */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Adress
          </label>
          <div className="mt-1">
            <input
              id="address"
              type="text"
              name="address"
              placeholder="Votre adresse complete..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            {errors.address && <p className="input-error-message text-red-700">{errors.address}</p>}
          </div>
        </div>

        {/* Country of residence */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Pays de residence
          </label>
          <div className="mt-1">
            <input
              id="country_of_residence"
              type="text"
              name="country_of_residence"
              placeholder="Ex: Australie"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.citizenship ? 'input-error' : ''}`}
              value={citizenship}
              onChange={(e) => setCitizenship(e.target.value)}
            />
            {errors.citizenship && <p className="input-error-message text-red-700">{errors.citizenship}</p>}
          </div>
        </div>

        {/* Type of sponsorship */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Type de sponsorship
          </label>
          <div className="mt-1">
            <input
              id="type_of_sponsorship"
              type="text"
              name="type_of_sponsorship"
              placeholder="cliquez ici pour écrire..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.typeOfSponsorship ? 'input-error' : ''}`}
              value={typeOfSponsorship}
              onChange={(e) => setTypeOfSponsorship(e.target.value)}
            />
            {errors.typeOfSponsorship && <p className="input-error-message text-red-700">{errors.typeOfSponsorship}</p>}
          </div>
        </div>

        {/* Reason of sponsorship */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Raison pour votre sponsorhsip
          </label>
          <div className="mt-1">
            <input
              id="raison_of_sponsorship"
              type="text"
              name="raison_of_sponsorship"
              placeholder="cliquez ici pour écrire..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.reasonOfSponsorship ? 'input-error' : ''}`}
              value={reasonOfSponsorship}
              onChange={(e) => setReasonOfSponsorship(e.target.value)}
            />
            {errors.reasonOfSponsorship && <p className="input-error-message text-red-700">{errors.reasonOfSponsorship}</p>}
          </div>
        </div>

        {/* receipt */}

        <div>
          <label htmlFor="receipt" className="block text-sm font-medium ">
            Numéro de votre réçu de payment
          </label>
          <div className="mt-1">
            <input
              id="receipt"
              type="text"
              name="receipt"
              placeholder="ex: 1777-7777"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
            dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={receipt}
              onChange={(e) => setReceipt(e.target.value)}
            />
            {errors.receipt && <p className="input-error-projectSummary text-red-700">{errors.receipt}</p>}
          </div>
        </div>

        {/* Commentaire */}
        <div>
          <label htmlFor="message" className="block text-sm font-medium">
            Commentaire
          </label>
          <div className="mt-1">
            <textarea
              id="feedback"
              name="feedback"
              rows="4"
              className={`input-field block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300
              focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
               dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.message ? 'input-error' : ''}`}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Laissez un message..."
            />
            {errors.message && <p className="input-error-message text-red-700">{errors.message}</p>}
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300
           font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default SponsorForm;
