/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React from 'react';

const Provinces = () => {
  const provinces = [
    {
      number: '1',
      name: 'Equateur',
      chefLieux: 'Mbandaka',
      langues: 'Lingala',
      superficie: '103 902 km²',
      territoires: ['Basankusu,', 'Bikoro', 'Bolomba', 'Bomongo', 'Ingende', 'Lukolela', 'Makanza'],
      territoireNumber: '7',
      ville: 'Ville de Bandaka',
      population: '...',
    },
    {

      number: '2',
      name: 'Sud-Ubangui',
      chefLieux: 'Sud-Ubangui',
      langues: 'Lingala',
      superficie: '51 648 km²',
      territoires: ['Budjala', ' Kungu', 'Libenge', 'Gemena'],
      territoireNumber: '4',
      ville: 'Gemena (chef-lieu de la province)',
      population: '...',
    },
    {

      number: '3',
      name: 'Nord-Ubangui',
      chefLieux: 'Gbadolite',
      langues: 'Lingala',
      superficie: '56 644 km²²',
      territoires: ['Bosobolo',
        'Businga', 'Mobayi- Mbongo', 'Yakoma'],
      territoireNumber: '4',
      ville: 'Ville de Gbadolite (chef-lieu de province)',
      population: '...',
    },
    {
      number: '4',
      name: 'Mongala (ex Equateur)',
      chefLieux: 'Lisala',
      langues: 'Lingala',
      superficie: '58 141 km²',
      territoires: ['Bongandanga', 'Bumba', 'Lisala'],
      territoireNumber: '3',
      ville: 'Lisala (chef-lieu de la province)',
      population: '...',
    },
    {
      number: '5',
      name: 'Bas-Uele (ex Province Orientale)',
      chefLieux: 'Buta',
      langues: 'Lingala',
      superficie: '148 331 km²',
      territoires: ['Ake', 'Ango', 'Bambesa', 'Bondo', 'PokoButa'],
      territoireNumber: '6',
      ville: 'Buta (chef-lieu de la province)',
      population: '...',
    },
    {
      number: '6',
      name: 'Haut-Uele (ex Province Orientale)',
      chefLieux: 'Isiro',
      langues: 'Lingala',
      superficie: '98 683 km²',
      territoires: ['Dungu', 'Faradje', 'Niangara', 'Rungu', 'Wamba', 'Watsa'],
      territoireNumber: '6',
      ville: 'Isoro (chef-lieu de province)',
      population: '...',
    },

    {
      number: '7',
      name: 'Ituri (ex Province Orientale)',
      chefLieux: 'Bunia',
      langues: 'Swahili',
      superficie: '65 658 km²',
      territoires: ['Mambasa', 'Irumu', 'Djugu', 'Mahagi', 'Aru'],
      territoireNumber: '5',
      ville: '...',
      population: '...',
    },
    {
      number: '8',
      name: 'Kongo Central (ex Bas-Congo)',
      chefLieux: 'Matadi',
      langues: 'Kikongo',
      superficie: '53 920 km²',
      territoires: ['Kasangulu', 'Kimvula', 'Lukula', 'Luozi', 'Madimba', 'Mbanza'],
      territoireNumber: '6',
      ville: '...',
      population: '...',
    },
    {
      number: '9',
      name: 'Kinshsa Capita Province',
      chefLieux: ' Kinshasa',
      langues: 'Lingala',
      superficie: '9 965 km²',
      territoires: ['Gombe', 'Kinsambu', 'Ngaliema', 'Mont Ngasula', "N'Djaba", 'Lemba', 'Matete', 'Limeté',
        'Linguala', 'Kinshasa', 'Barumbu', 'Masiina', 'Kimbaseke', "N'sele", "N'Djili", "N'Djili-ndili",
        'Selemboa', 'Makala', 'Kalamu', 'Kasavubu', 'Bumbu', 'Ngiri-Ngiri', 'Mbandalungua', 'Maluku'],
      territoireNumber: '24',
      ville: 'Kinshasa',
      population: '...',
    },
    {
      number: '10',
      name: 'Mai-Ndombe (ex Bandundu)',
      chefLieux: 'Inongo',
      langues: 'Lingala',
      superficie: '127 465 km²',
      territoires: ['Bolobo', 'Kiri', 'Kutu', 'Kwamouth', 'Mushie', 'Oshwe', 'Yumbi', 'Inongo'],
      territoireNumber: '8',
      ville: 'Inongo (chef-lieu de la province)',
      population: '...',
    },
    {
      number: '11',
      name: 'Kwilu (ex Bandundu)',
      chefLieux: 'Bandundu',
      langues: 'Kikongo',
      superficie: '78 219 km²',
      territoires: ['Bagata', 'Bulungu', 'Gunugu', 'Idiofa', 'Masimanimba'],
      territoireNumber: '5',
      ville: 'Ville de Bandundu (chef-lieu de la province).',
      population: '...',
    },
    {
      number: '12',
      name: 'Kwango (ex Bandundu)',
      chefLieux: 'Kenge',
      langues: 'Kikongo',
      superficie: '89 974 km²',
      territoires: ['Feshi', 'Kahemba', 'Kasongo-Lunda', 'Kenge', 'Popokabaka'],
      territoireNumber: '5',
      ville: 'Kenge',
      population: '',
    },
    {
      number: '13',
      name: 'Tshuapa (ex Equateur)',
      chefLieux: 'Boende',
      langues: 'Lingala',
      superficie: '132 957 km²',
      territoires: ['Befala', 'Bokungu', 'Djolu', 'Ikela', 'Monkoto', 'Boende'],
      territoireNumber: '6',
      ville: 'Boende chef-lieu de la province',
      population: '...',
    },
    {
      number: '14',
      name: 'Tshopo (ex Province Orientale)',
      chefLieux: 'Kisangani',
      langues: 'Swahili',
      superficie: '199 567 km²',
      territoires: ['Bafwasende', 'Banalia', 'Basoko', 'Isangi', 'Opala', 'Ubundu', 'Yahuma'],
      territoireNumber: '7',
      ville: 'ville de Kisangani',
      population: '...',
    },
    {
      number: '15',
      name: 'Sankuru (ex Kasaï Oriental)',
      chefLieux: 'Lusambo',
      langues: 'Lotetela',
      superficie: '104 331 km²',
      territoires: ['Katako-Kombe', 'Kole', 'Lodja', 'Lomela', 'Lubefu', 'Lusambo'],
      territoireNumber: '6',
      ville: 'Lusambo',
      population: '...',
    },
    {
      number: '16',
      name: 'Kasaï (ex Kasaï Occidental)',
      chefLieux: 'Tshikapa',
      langues: 'Tshiluba',
      superficie: '95 631 km²',
      territoires: ['Dekese', 'Ilebo', 'Kamonia', 'Mueka'],
      territoireNumber: '4',
      ville: 'Tshikapa',
      population: '...',
    },
    {
      number: '17',
      name: 'Kasaï Oriental (ex Kasaï Oriental)',
      chefLieux: 'Mbuji-Mayi',
      langues: 'Tshiluba',
      superficie: '9 481 km²',
      territoires: ['...'],
      territoireNumber: '',
      ville: 'Mbuji-Mayi',
      population: '...',
    },
    {
      number: '18',
      name: 'Kasaï Central (ex Kasaï Occidental)',
      chefLieux: 'Kananga',
      langues: 'Tshiluba',
      superficie: '60 958 km²',
      territoires: ['Demba', 'Dibaya', 'Dimbelenge', 'Kazumba', 'Luiza'],
      territoireNumber: '5',
      ville: 'Kananga',
      population: '...',
    },
    {
      number: '19',
      name: 'Lomami (ex Kasaï Oriental)',
      chefLieux: 'Kabinda',
      langues: 'Tshiluba',
      superficie: '56 426 km²',
      territoires: ['Kamji', 'Kimvula', 'Lulao', 'Luilu', 'Ngandajika'],
      territoireNumber: '5',
      ville: 'Kabinda',
      population: '...',
    },
    {
      number: '20',
      name: 'Nord Kivu',
      chefLieux: 'Goma',
      langues: 'Swahili',
      superficie: '59 483 km²',
      territoires: ['Masisi', 'Nyiragongo', 'Rutshuru', 'Walikale'],
      territoireNumber: '4',
      ville: 'Goma',
      population: '...',
    },
    {
      number: '21',
      name: 'Sud Kivu',
      chefLieux: 'Bukavu',
      langues: 'Swahili',
      superficie: '65 070 km²',
      territoires: ['Lubero', 'Masisi', 'Nyiragongo', 'Rutshuru'],
      territoireNumber: '4',
      ville: 'Bukavu',
      population: '...',
    },
    {
      number: '22',
      name: 'Maniema',
      chefLieux: 'Kindu',
      langues: 'Swahili',
      superficie: '132 520 km²',
      territoires: ['Kambare', 'Kailo', 'Kasongo', 'Kibombo', 'Lubutu', 'Pangi', 'Punia'],
      ville: 'Kindu',
      population: '...',
    },

    {
      number: '23',
      name: 'Tanganyika (ex Katanga)',
      chefLieux: 'Kalemi',
      langues: 'Swahili',
      superficie: '134 940 km²',
      territoires: ['Kabalo', 'Kongolo', 'Manono', 'Moba', 'Nyunzu', 'Kalemie'],
      territoireNumber: '6',
      ville: 'Kalemie',
      population: '...',
    },
    {
      number: '24',
      name: 'Haut-Katanga (ex Katanga)',
      chefLieux: 'Lubumbashi',
      langues: 'Swahili',
      superficie: '132 425 km²',
      territoires: ['Kambove', 'Kasengue', 'Kipushi', 'Mitwaba', 'Pweto', 'Sakania'],
      territoireNumber: '6',
      ville: 'Ville de Lubumbashi',
      population: '...',
    },
    {
      number: '25',
      name: 'Lualaba (ex Katanga)',
      chefLieux: 'Kolwezi',
      langues: 'Swahili',
      superficie: '121 308 km²',
      territoires: ['Dilolo', 'Kapanga', 'Lubudi', 'Mutshatsha', 'Sandoa', 'Kasaji (ville)'],
      territoireNumber: '5',
      ville: 'Ville de Lualaba',
      population: '...',
    },
    {
      number: '26',
      name: 'Haut-Lomami (ex Katanga)',
      chefLieux: 'Kamina',
      langues: 'Swahili',
      superficie: '108 204 km²',
      territoires: ['Bukama', 'Kabongo', 'Kanyama', 'Malemba-Nkulu', 'Kamina'],
      territoireNumber: '5',
      ville: 'Kamina',
      population: '5',
    },

  ];

  return (
    <div className="container p-2 mx-auto sm:p-4 text-gray-100">
      <h2 className="mb-4 text-2xl font-semibold leadi">Invoices</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full text-xs">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead className="bg-gray-700">
            <tr className="text-left">
              <th className="p-3">N°</th>
              <th className="p-3">Province</th>
              <th className="p-3">Chef-Lieux</th>
              <th className="p-3">Langues</th>
              <th className="p-3">Superficie</th>
              <th className="p-3">Territoires</th>
              <th className="p-3">Ville</th>
              <th className="p-3">Population</th>
            </tr>
          </thead>
          <tbody>
            {provinces.map(({
              number, name, chefLieux, langues, superficie, territoires, ville, population, territoireNumber,
            }) => (
              <tr className="border-b border-opacity-20 border-gray-700 bg-gray-900">
                <td className="p-3">
                  <p>{number}</p>
                </td>
                <td className="p-3">
                  <p>{name}</p>
                </td>
                <td className="p-3">
                  <p>{chefLieux}</p>
                </td>
                <td className="p-3">
                  <p className="text-gray-400">{langues}</p>
                </td>
                <td className="p-3">
                  <p className="text-gray-400">{superficie}</p>
                </td>
                <td className="p-3 text-left">
                  <span className="my-5 p-1 font-semibold rounded-md bg-violet-400 text-gray-900">
                    <span>
                      {territoireNumber}
                      {' '}
                      territoires:
                    </span>
                  </span>
                  {territoires.map((territoireName) => (
                    <p className="text-gray-400">{territoireName}</p>
                  ))}
                </td>
                <td className="p-3 text-left">
                  <p>{ville}</p>
                </td>

                <td className="p-3 text-center">
                  <p>{population}</p>
                </td>
              </tr>
            ))}
            ;
          </tbody>
        </table>
      </div>
    </div>

  );
};

export default Provinces;
