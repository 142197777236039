import AmbassadorForm from '../components/AmbassadorForm';
import NavBar from '../components/NavBar';

const Ambassador = () => (
  <div>
    <NavBar />
    <AmbassadorForm />
  </div>
);

export default Ambassador;
