import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSpeakers } from '../redux/speaker/store';

const SpeakersShow = () => {
  const dispatch = useDispatch();
  const speakers = useSelector((state) => state.speakersReducer.speakers);

  const isLoading = !speakers || speakers.length === 0;

  useEffect(() => {
    dispatch(getSpeakers());
  }, [dispatch]);

  return (
    <div className="px-4">
      <h1 className="text-4xl font-bold text-center my-3 custom-font-bold">
        {' '}
        OUR SPEAKERS
      </h1>
      {isLoading ? (
        <div className="flex justify-center  h-screen mt-28">
          <div className="animate-spin rounded-full border-t-4 border-blue-500 border-opacity-50 h-12 w-12 mr-3" />
          <p className="text-xl text-gray-700 dark:text-gray-300 custom-font mt-3">
            Loading speakers...
          </p>
        </div>
      ) : (
        <ul className="space-y-4">
          {speakers.map((speaker) => (speaker ? (
            <li key={speaker.id} className="mb-4">
              <div className="p-6 sm:p-12 bg-gray-900 text-gray-100 border border-gray-700 rounded-lg shadow-md">
                <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-6 md:flex-row">
                  <img
                    src={`https://www.rdc145territoires.me/${speaker.photo.url}`}
                    alt=""
                    className="self-center flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start dark:bg-gray-500 dark:border-gray-700"
                    style={{ objectFit: 'cover', objectPosition: 'center' }}
                  />
                  <div className="flex flex-col">
                    <h4 className="text-lg font-semibold text-center md:text-left ">
                      {speaker.first_name}
&nbsp;
                      {speaker.last_name}
                    </h4>
                    <p className="dark:dark:dark:text-gray-400 custom-font">
                      {speaker.biography}
                      {' '}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ) : null))}
        </ul>
      )}
    </div>
  );
};

export default SpeakersShow;
