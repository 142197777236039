/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import Navbar from '../components/NavBar';

const Awards = () => (
  <div>
    <Navbar />
    <div className="px-5 md:px-20 py-10 mx-auto">
      <h2 className="mb-12 text-4xl font-bold leadi text-center md:text-left sm:text-5xl">LES AWARDS </h2>
      <p>
        En marge de la conference,
        des evenements specaux importants sont prevus. Ce sont, entre autres:
      </p>

      <div>
        <div>
          <h3 className=" my-5 text-lg font-bold leadi text-center md:text-left flex space-x-3 align-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
            </svg>
            <span>Une soiree festive</span>
          </h3>
          <p>
            Cette activite sera animee par des artistes congolais de renom et
            des jeunes artistes encore en herbe.
            La conference se propose de promouvoir
            une nouvelle musique sur des themes de developpement des communautes locales.
            {' '}
          </p>
        </div>

        <div>
          <h3 className=" my-5 text-lg font-bold leadi text-center md:text-left flex space-x-3 align-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
            </svg>
            <span>Trophee Miss Territoires</span>
          </h3>
          <p>
            La Miss Territoires est un concours base sur la meritocratie.
            La laureate sera proclamee a la suite du concours organise en ligne.
            Son mandat est de promouvoir le PDL 145 T afin d’attirer des
            investisseurs en faveur des territoires.
            {' '}
          </p>
        </div>

        <div>
          <h3 className=" my-5 text-lg font-bold leadi text-center md:text-left flex space-x-3 align-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
            </svg>
            <span>Prix Meilleurs acteurs des 145 T</span>
          </h3>
          <p>
            Ce prix est attribue aux personnes qui
            se distinguent pour leur engagement en faveur du PDL 145 T.
            Quatre categories sont retenus: Projet, Investisseur, Entreprise, Medias.
            {' '}
          </p>
        </div>

      </div>
    </div>
  </div>
);

export default Awards;
