/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { addSpeakers } from '../redux/speaker/store';

const SpeakerForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [presentation, setPresentation] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [citizenship, setCitizenship] = useState('');
  const [biography, setBiography] = useState('');
  const [biographyCharCount, setBiographyCharCount] = useState(0);
  const [occupation, setOccupation] = useState('');
  const [aboutpdl145, setAboutpdl145] = useState('');
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const [errors, setErrors] = useState({});

  // form redirection and redux state access
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notify = () => toast.success('Votre candidature  a été envoyé avec succès', {
    position: 'top-right',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });

  const onBiographyChange = (e) => {
    const text = e.target.value;
    setBiography(text);
    setBiographyCharCount(text.length);
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    // for first name

    if (firstName.length === 1 || firstName.length <= 3) {
      formIsValid = false;
      newErrors.firstName = 'Votre nom doit avoir plus 3 lettres';
    }

    if (firstName.trim() === '') {
      formIsValid = false;
      newErrors.firstName = 'Please entrez votre name';
    }

    // for last name

    if (lastName.length === 1 || lastName.length <= 3) {
      formIsValid = false;
      newErrors.lastName = 'Votre nom doit avoir plus 3 lettres';
    }

    if (lastName.trim() === '') {
      formIsValid = false;
      newErrors.firstName = 'Please entrez votre name';
    }

    //  presentation

    if (presentation.length === 1 || presentation.length <= 6) {
      formIsValid = false;
      newErrors.presentation = 'Votre présentation doit avoir plus de 3 mots';
    }

    if (presentation.trim() === '') {
      formIsValid = false;
      newErrors.presentation = 'Please entrez présentation';
    }

    // for email

    if (!email) {
      formIsValid = false;
      newErrors.email = 'Please entrez votre email';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      newErrors.email = 'Please entrez votre un email valide';
    }

    // for phone number

    if (!isValidPhoneNumber(phone)) {
      formIsValid = false;
      newErrors.phone = 'Please entrez un numero de telephone valide';
    } else if (phone.trim() === '') {
      formIsValid = false;
      newErrors.phone = 'Le numero de telephone est obligatoire';
    }

    // for address

    if (address.length === 1 || address.length <= 15) {
      formIsValid = false;
      newErrors.address = 'Votre adress doit avoir plus 3 mots';
    }

    if (!address) {
      formIsValid = false;
      newErrors.address = 'Please entrez votre adresse';
    }

    // Biography
    if (biography.length < 600) {
      formIsValid = false;
      newErrors.biography = 'Votre biographie doit avoir au moins 600 caractères';
    }
    if (!biography) {
      formIsValid = false;
      newErrors.biography = 'Please, faites-nous savoir votre résumé de biographie';
    }

    if (!biography) {
      formIsValid = false;
      newErrors.biography = 'Please, faites-nous savoir votre resume de biographie';
    }

    // occupation
    if (occupation.length === 1 || occupation.length <= 3) {
      formIsValid = false;
      newErrors.occupation = 'Votre occupation doit avoir au moins un mot';
    }

    if (!occupation) {
      formIsValid = false;
      newErrors.occupation = 'Please dites-nous ce que vous faites dans la vie';
    }

    // country of residence
    if (citizenship.length === 1 || citizenship.length <= 3) {
      formIsValid = false;
      newErrors.citizenship = 'Votre pays de residence doit etre correct';
    }

    if (!citizenship) {
      formIsValid = false;
      newErrors.citizenship = 'Please enterez votre pays de residence';
    }

    // speakers photo

    if (!selectedFile) {
      formIsValid = false;
      newErrors.selectedFile = 'Please enterez votre photo';
    }

    // How did you hear about us

    if (aboutpdl145.length === 1 || message.aboutpdl145 <= 3) {
      formIsValid = false;
      newErrors.aboutpdl145 = 'Votre information doit avoir plus 3 lettres';
    }

    if (!aboutpdl145) {
      formIsValid = false;
      newErrors.aboutpdl145 = 'Please dites-nous comment vous avez entendu parler de nous';
    }

    // Information addtionelle

    if (message.length === 1 || message.length <= 3) {
      formIsValid = false;
      newErrors.message = 'Votre information en addition doit avoir plus 3 lettres';
    }

    if (!message) {
      formIsValid = false;
      newErrors.message = 'Please entrez votre message';
    }

    setErrors(newErrors);
    return formIsValid;
  };

  // SUBMIT ALL THE DATA FOR PROCESSING IN THE STORE MANAGEMENT IN THIS CASE ADD
  const data = {
    first_name: firstName,
    last_name: lastName,
    presentation,
    email,
    phone,
    address,
    citizenship,
    biography,
    occupation,
    feedback: message,
    hear_about_us: aboutpdl145,
    photo: selectedFile,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Handle form submission if everything is ok send to the server

      navigate('/');
      dispatch(addSpeakers(data));
      notify();
    }
  };

  return (
    <div className="shadow-2xl w-5/6 mx-auto p-10 mt-3 bg-gray-100 mb-6 rounded">

      <p className="mb-5 text-lg font-medium">{'S\'il vous plait, Veuillez completer tous les champs de ce formulaire pour que votre candidature soit pris en considération.'}</p>

      <form onSubmit={handleSubmit} className="space-y-6">

        {/* Fisrt Name */}

        <div>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
            Votre Nom
          </label>
          <div className="mt-1">
            <input
              id="name"
              type="text"
              name="firstName"
              placeholder="Ex: Musonda"
              className={`input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
               focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
               dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.firstName ? 'input-error' : ''}`}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {errors.firstName && <p className="input-error-message text-red-700">{errors.firstName}</p>}
          </div>
        </div>

        {/* Last Name */}

        <div>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
            Votre prenom
          </label>
          <div className="mt-1">
            <input
              id="lastName"
              type="text"
              name="lastName"
              placeholder="Ex: Pamphile"
              className={`input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
               focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
               dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.lastName ? 'input-error' : ''}`}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {errors.lastName && <p className="input-error-message text-red-700">{errors.lastName}</p>}
          </div>
        </div>

        {/* Email */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="email"
              name="email"
              placeholder="ex: edmjvnews@gmail.com"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p className="input-error-message text-red-700">{errors.email}</p>}
          </div>
        </div>

        {/* Phone number */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Phone number
          </label>
          <div className="mt-1">
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="FR"
              value={phone}
              onChange={setPhone}
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm
                           rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 `}
            />
            {errors.phone && <p className="input-error-message text-red-700">{errors.phone}</p>}
          </div>
        </div>

        {/* Address */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Adresse
          </label>
          <div className="mt-1">
            <input
              id="address"
              type="text"
              name="address"
              placeholder="Votre adresse complete..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            {errors.address && <p className="input-error-message text-red-700">{errors.address}</p>}
          </div>
        </div>

        {/* Country of residence */}

        <div>
          <label htmlFor="country_of_residence" className="block text-sm font-medium ">
            Pays de residence
          </label>
          <div className="mt-1">
            <input
              id="country_of_residence"
              type="text"
              name="country_of_residence"
              placeholder="Ex: Australie"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.citizenship ? 'input-error' : ''}`}
              value={citizenship}
              onChange={(e) => setCitizenship(e.target.value)}
            />
            {errors.citizenship && <p className="input-error-message text-red-700">{errors.citizenship}</p>}
          </div>
        </div>

        {/* Speakers photo */}

        <div>
          <label htmlFor="selectedFile" className="block text-sm font-medium custom-file-upload">
            Votre photo
          </label>
          <div className="mt-1">
            <input
              id="selectedFile"
              type="file"
              name="selectedFile"
              accept="image/*"
              placeholder="cliquez ici pour ajouter votre photo"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.selectedFile ? 'input-error' : ''}`}
              value={selectedFile}
              onChange={(e) => setSelectedFile(e.target.value)}
            />
            {errors.selectedFile && <p className="input-error-message text-red-700">{errors.selectedFile}</p>}
          </div>
        </div>

        {/* Occupation */}

        <div>
          <label htmlFor="occupation" className="block text-sm font-medium ">
            Votre occupation
          </label>
          <div className="mt-1">
            <input
              id="occupation"
              type="text"
              name="occupation"
              placeholder="cliquez ici pour écrire..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.occupation ? 'input-error' : ''}`}
              value={occupation}
              onChange={(e) => setOccupation(e.target.value)}
            />
            {errors.occupation && <p className="input-error-message text-red-700">{errors.occupation}</p>}
          </div>
        </div>

        {/* Breve Biography */}

        <div>
          <label htmlFor="biography" className="block text-sm font-medium ">
            Biographie (en quelques phrases)
          </label>
          <div className="mt-1">
            <textarea
              id="biography"
              name="biography"
              rows="4"
              className={`input-field block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300
                focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.biography ? 'input-error' : ''}`}
              value={biography}
              onChange={onBiographyChange} // Update this line
              placeholder="cliquez ici pour écrire..."
            />
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {biographyCharCount}
              /600 caractères
            </p>
            {errors.biography && <p className="input-error-message text-red-700">{errors.biography}</p>}
          </div>
        </div>

        {/* presentation */}

        <div>
          <label htmlFor="presentation" className="block text-sm font-medium ">
            Résumé de votre présentation (en quelques phrases)
          </label>
          <div className="mt-1">

            <textarea
              id="presentation"
              name="presentation"
              rows="4"
              className={`input-field block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300
              focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
               dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.presentation ? 'input-error' : ''}`}
              value={presentation}
              onChange={(e) => setPresentation(e.target.value)}
              placeholder="cliquez ici pour écrire..."
            />

            {errors.presentation && <p className="input-error-projectSummary text-red-700">{errors.presentation}</p>}
          </div>
        </div>

        {/* How did you hear about us */}

        <div>
          <label htmlFor="aboutpdl145" className="block text-sm font-medium ">
            Comment avez-vous entendu parler de nous
          </label>
          <div className="mt-1">

            <textarea
              id="aboutpdl145"
              name="aboutpdl145"
              rows="4"
              className={`input-field block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300
              focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
               dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.aboutpdl145 ? 'input-error' : ''}`}
              value={aboutpdl145}
              onChange={(e) => setAboutpdl145(e.target.value)}
              placeholder="cliquez ici pour écrire..."
            />

            {errors.aboutpdl145 && <p className="input-error-projectSummary text-red-700">{errors.aboutpdl145}</p>}
          </div>
        </div>

        {/* Information addtionelle */}
        <div>
          <label htmlFor="message" className="block text-sm font-medium">
            Information addtionelle
          </label>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows="4"
              className={`input-field block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300
              focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
               dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.message ? 'input-error' : ''}`}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Laissez un message..."
            />
            {errors.message && <p className="input-error-message text-red-700">{errors.message}</p>}
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300
           font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default SpeakerForm;
