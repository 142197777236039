import { configureStore, combineReducers } from '@reduxjs/toolkit';
import participanReducer from './participant/Store';
import sponsorsReducer from './sponsor/Store';
import ambassadorReducer from './ambassador/Store';
import speakersReducer from './speaker/store';

const rootReducer = combineReducers({
  participanReducer,
  sponsorsReducer,
  speakersReducer,
  ambassadorReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
