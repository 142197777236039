/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable function-paren-newline */
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

import { toast } from 'react-toastify';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const form = useRef();
  const navigate = useNavigate();

  const notify = () => toast.success('Bravo!!   Votre message a été envoyé avec succès !', {
    position: 'top-right',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    if (name.length === 1 || name.length <= 3) {
      formIsValid = false;
      newErrors.name = 'Votre nom doit avoir plus 3 lettres';
    }

    if (name.trim() === '') {
      formIsValid = false;
      newErrors.name = 'Please entrez votre name';
    }

    if (!email) {
      formIsValid = false;
      newErrors.email = 'Please entrez votre email';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      newErrors.email = 'Please entrez votre un email valide';
    }

    if (message.length === 1 || message.length <= 5) {
      formIsValid = false;
      newErrors.message = 'Votre message doit avoir plus 5 lettres';
    }

    if (!message) {
      formIsValid = false;
      newErrors.message = 'Please entrez votre message';
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Handle form submission
      // EmailJSResponseStatus.sendForm
      emailjs.sendForm('service_fxc6uip', 'template_tvns1of', e.target, 'YFWfBivqjvHRPbnmL')
        .then((result) => {
          // console.log(result.text);
          navigate('/');
          notify();
        }, (error) => {
          // console.log(error.text);
        },
        );
    }
  };

  return (
    <div className="shadow-2xl w-3/4 mx-auto p-10 mt-3 bg-gray-100 mb-6 rounded">

      <p className="mb-5 text-lg font-medium">{'S\'il vous plait, laissez-nous votre message et nous vous contacterons au plus vite.'}</p>
      <form ref={form} onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
            Nom complet
          </label>
          <div className="mt-1">
            <input
              id="name"
              type="text"
              name="name"
              placeholder="ex: Pamphile Musonda"
              className={`input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
               focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
               dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.name ? 'input-error' : ''}`}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <p className="input-error-message text-red-700">{errors.name}</p>}
          </div>
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="email"
              name="email"
              placeholder="ex: edmjvnews@gmail.com"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p className="input-error-message text-red-700">{errors.email}</p>}
          </div>
        </div>
        <div>
          <label htmlFor="message" className="block text-sm font-medium">
            Message
          </label>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows="4"
              className={`input-field block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300
              focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
               dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.message ? 'input-error' : ''}`}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Laissez un message..."
            />
            {errors.message && <p className="input-error-message text-red-700">{errors.message}</p>}
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300
           font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
