import React from 'react';
import NavBar from '../components/NavBar';
import ContactForm from '../components/ContactForm';

const Contact = () => (
  <div>
    <NavBar />
    <ContactForm />
  </div>
);

export default Contact;
