/* eslint-disable max-len */
import React from 'react';

const ApropoText = () => (
  <div>
    <div className=" flex flex-col gap-3 text-jusify text-md  lg:mx-20 lg:text-md pb-10 pt-5">
      <p>
        {`La conférence internationale sur le développement local en RD-Congo, deuxième édition,
        est une initiative citoyenne d’appropriation du programme de développement à la base
        en cours dans le pays. Elle vise l’échange d’expérience et le renforcement des capacités
        entre le groupe d’experts, élus et dirigeants locaux, chefs d’entreprises d’Australie,
        du Canada et des pays africains ayant réussi des modèles économiques basés sur le
        développement local pour inspirer les participants des 145 territoires de la RDC.`}
        {' '}
      </p>

      <p>
        {`La conférence internationale sur le développement local en RD-Congo, deuxième édition,
        est une initiative citoyenne d’appropriation du programme de développement à la base
        en cours dans le pays. Elle vise l’échange d’expérience et le renforcement des capacités
        entre le groupe d’experts, élus et dirigeants locaux, chefs d’entreprises d’Australie,
        du Canada et des pays africains ayant réussi des modèles économiques basés sur le
        développement local pour inspirer les participants des 145 territoires de la RDC.`}
      </p>
      <p>
        {`1-Création d’un fonds d’investissement comme réponse au déficit d’une banque
        spécialisée dans l’accompagnement de l’entrepreneuriat et du tiers secteur
        au plus bas niveau de l'échelle communautaire`}
      </p>

      <p>
        {`2-Lancement d’un incubateur pour la création d’emplois stratégiques en faveur
        des étudiants, des jeunes-adultes et des femmes, des catégories de la population
        très touchées par la pauvreté`}
      </p>
      <p>
        {'3-Lutte contre l’exode rural et l\'émigration des cerveaux'}
      </p>
    </div>
  </div>
);

export default ApropoText;
