import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import NavBar from '../components/NavBar';
import ParticipantForm from '../components/ParticipantForm';

const Participant = () => {
  const notify = () => toast.success('Votre payment a été éfféctué, veuillez compléter ce formulaire!', {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });

  useEffect(() => {
    notify();
  }, []);

  return (
    <div>
      <NavBar />
      <ParticipantForm />
      <ToastContainer />
    </div>
  );
};

export default Participant;
