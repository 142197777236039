/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const navigation = [
  { name: 'Accueil', path: '/', current: false },
  { name: 'A propos', path: '/Apropos', current: false },
  { name: 'Provinces', path: '/RdcTerritoires', current: false },
  { name: 'Programmes', path: '/Programme', current: false },
  {
    name: 'Enregistrements', path: '/Enregistrement', current: false, classNames: 'w-[90px]',
  },
  { name: 'Speakers', path: '/Speakers', current: false },
  { name: 'News', path: '/News', current: false },
  { name: 'Gallery', path: '/Gallery', current: false },
  { name: 'Contact', path: '/Contact', current: false },
];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

const NavBar = () => {
  const linkStyles = `
    block text-center 
    px-[7.4px] py-1 
    text-[12px] font-bold leading-4 
    bg-blue-900 text-white hover:bg-blue-700 
    font-[Cuprum, Arial, Helvetica, sans-serif]
    whitespace-nowrap 
    h-[42px] flex items-center justify-center
  `;
  const style = {
    verticalAlign: 'middle',
    paddingLeft: '5px',
    font: 'bold 16px Cuprum, Arial, Helvetica, sans-serif',
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
    boxSizing: 'content-box',
    borderStyle: 'none',
    borderWidth: '0px',
    textAlign: 'center',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <>
      <Disclosure as="nav" className="pt-1 pb-1 g-white p">
        {({ open }) => (
          <>
            <div className="mx-auto mt-3 " style={{ maxWidth: '90%' }}>
              <div className="relative flex items-center justify-between h-[42px]">
                <div className="flex items-center ">
                  <div className="hidden lg:block">
                    <div className="flex items-baseline space-x-1 ">
                      {navigation.map(
                        (item) => (
                          <NavLink
                            key={item.name}
                            to={item.path}
                            className={linkStyles}
                            aria-current={item.current ? 'page' : undefined}
                            style={style}
                          >
                            {item.name}
                          </NavLink>
                        ),
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-800 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="bg-white md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    as="a"
                    to={item.path}
                    className={linkStyles}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default NavBar;
