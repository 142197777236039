/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addAmbassadors, getAmbassadors } from '../redux/ambassador/Store';

const AmbassadorForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [citizenship, setCitizenship] = useState('');
  const [presentation, setPresentation] = useState('');
  const [position, setPosition] = useState('');
  const [comment, setComment] = useState('');
  const [reasonOfChoice, setReasonOfChoice] = useState('');
  const [territoire, SetTerritoire] = useState('');
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notify = () => toast.success('Votre candidature  a été envoyé avec succès !', {
    position: 'top-right',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};

    // for first name
    if (firstName.length === 1 || firstName.length <= 3) {
      formIsValid = false;
      newErrors.firstName = 'Votre nom doit avoir plus 3 lettres';
    }

    if (firstName.trim() === '') {
      formIsValid = false;
      newErrors.firstName = 'Please entrez votre nom';
    }

    // for last name
    if (lastName.length === 1 || lastName.length <= 3) {
      formIsValid = false;
      newErrors.lastName = 'Votre nom doit avoir plus 3 lettres';
    }

    if (lastName.trim() === '') {
      formIsValid = false;
      newErrors.firstName = 'Please entrez votre name';
    }

    // for email
    if (!email) {
      formIsValid = false;
      newErrors.email = 'Please entrez votre email';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      newErrors.email = 'Please entrez votre un email valide';
    }

    // for phone number
    if (!isValidPhoneNumber(phone)) {
      formIsValid = false;
      newErrors.phone = 'Please entrez un numero de telephone valide';
    } else if (phone.trim() === '') {
      formIsValid = false;
      newErrors.phone = 'Le numero de telephone est obligatoire';
    }

    // for address
    if (address.length === 1 || address.length <= 15) {
      formIsValid = false;
      newErrors.address = 'Votre adress doit avoir plus 3 mots';
    }

    if (!address) {
      formIsValid = false;
      newErrors.address = 'Please entrez votre adresse';
    }

    // Raison du choix
    if (reasonOfChoice.length === 1 || reasonOfChoice.length <= 20) {
      formIsValid = false;
      newErrors.reasonOfChoice = 'La raison de votre choix pour devenir notre sponsor doit avoir plus 3 mots';
    }

    if (!reasonOfChoice) {
      formIsValid = false;
      newErrors.reasonOfChoice = 'Please, dites-nous pourquoi vous voulez devenir un ambassadeur';
    }

    // territoire choisi
    if (territoire.length === 1 || territoire.length <= 3) {
      formIsValid = false;
      newErrors.territoire = 'Votre territoire choisi doit avoir au moins un mot';
    }

    if (!territoire) {
      formIsValid = false;
      newErrors.territoire = 'Please, dites nous quel est le nom du territoire dont vous voulez devenir ambassadeur';
    }

    // country of residence
    if (citizenship.length === 1 || citizenship.length <= 3) {
      formIsValid = false;
      newErrors.citizenship = 'Votre pays de residence doit etre correct';
    }

    if (!territoire) {
      formIsValid = false;
      newErrors.citizenship = 'Please enterez votre pays de residence';
    }

    // position
    if (position.length === 1 || position.length <= 3) {
      formIsValid = false;
      newErrors.position = 'Votre position doit etre correct';
    }

    if (!position) {
      formIsValid = false;
      newErrors.position = 'Please enterez la position ';
    }

    // For breve presentation

    if (presentation.length === 1 || presentation.length <= 3) {
      formIsValid = false;
      newErrors.presentation = 'Votre brève présentation doit avoir plus 3 lettres';
    }

    if (!presentation) {
      formIsValid = false;
      newErrors.presentation = 'Please entrez une brève présentation';
    }

    // for comment
    if (comment.length === 1 || comment.length <= 3) {
      formIsValid = false;
      newErrors.comment = 'Votre comment doit avoir plus 3 lettres';
    }

    if (!comment) {
      formIsValid = false;
      newErrors.comment = 'Please entrez votre comment';
    }

    setErrors(newErrors);
    return formIsValid;
  };

  // SUBMIT ALL THE DATA FOR PROCESSING IN THE STORE MANAGEMENT IN THIS CASE ADD
  const data = {
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    address,
    citizenship,
    presentation,
    territoire,
    reason_of_choice: reasonOfChoice,
    comment,
    position,
  };

  //   Handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Handle form submission if everything is ok send to the server

      navigate('/');
      dispatch(addAmbassadors(data));
      notify();
    }
  };

  // testing if we are getting the data
  useEffect(() => {
    dispatch(getAmbassadors());
  }, [dispatch]);

  return (
    <div className="shadow-2xl w-5/6 mx-auto p-10 mt-3 bg-gray-100 mb-6 rounded">

      <p className="mb-5 text-lg font-medium">{'S\'il vous plait, Veuillez completer tous les champs de ce formulaire pour que votre candidature soit pris en considération.'}</p>

      <form onSubmit={handleSubmit} className="space-y-6">

        {/* Fisrt Name */}

        <div>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
            NOM
          </label>
          <div className="mt-1">
            <input
              id="name"
              type="text"
              name="firstName"
              placeholder="Ex: Musonda"
              className={`input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
               focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
               dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.firstName ? 'input-error' : ''}`}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {errors.firstName && <p className="input-error-comment text-red-700">{errors.firstName}</p>}
          </div>
        </div>

        {/* Last Name */}

        <div>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
            PRÉNOM
          </label>
          <div className="mt-1">
            <input
              id="name"
              type="text"
              name="name"
              placeholder="Ex: Pamphile"
              className={`input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
               focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
               dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.lastName ? 'input-error' : ''}`}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {errors.lastName && <p className="input-error-comment text-red-700">{errors.lastName}</p>}
          </div>
        </div>

        {/* Email */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            E-MAIL
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="email"
              name="email"
              placeholder="ex: edmjvnews@gmail.com"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p className="input-error-comment text-red-700">{errors.email}</p>}
          </div>
        </div>

        {/* Phone number */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            TEL
          </label>
          <div className="mt-1">
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="FR"
              value={phone}
              onChange={setPhone}
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm
                          rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 `}
            />
            {errors.phone && <p className="input-error-comment text-red-700">{errors.phone}</p>}
          </div>
        </div>

        {/* Address */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            ADRESSE
          </label>
          <div className="mt-1">
            <input
              id="addresse"
              type="text"
              name="address"
              placeholder="Votre adresse complete..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            {errors.address && <p className="input-error-comment text-red-700">{errors.address}</p>}
          </div>
        </div>

        {/* Country of residence */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            PAYS DE RÉSIDENCE
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="text"
              name="email"
              placeholder="Ex: Australie"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.citizenship ? 'input-error' : ''}`}
              value={citizenship}
              onChange={(e) => setCitizenship(e.target.value)}
            />
            {errors.citizenship && <p className="input-error-comment text-red-700">{errors.citizenship}</p>}
          </div>
        </div>

        {/* POSITION */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            POSITION (AMBASSADEUR/DRICE)
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="text"
              name="position"
              placeholder="Ex: AMBASSADRICE"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.experience ? 'input-error' : ''}`}
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
            {errors.position && <p className="input-error-comment text-red-700">{errors.position}</p>}
          </div>
        </div>

        {/* TERRITOIRE CHOISI */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            TERRITOIRE CHOISI
          </label>
          <div className="mt-1">
            <input
              id="territoire"
              type="text"
              name="territoire"
              placeholder="Ex: Haut-Katanga"
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={territoire}
              onChange={(e) => SetTerritoire(e.target.value)}
            />
            {errors.territoire && <p className="input-error-comment text-red-700">{errors.territoire}</p>}
          </div>
        </div>

        {/* RAISONS DE VOTRE CHOIX */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            RAISONS DE VOTRE CHOIX
          </label>
          <div className="mt-1">
            <input
              id=""
              type="text"
              name="reasonOfChoice"
              placeholder="cliquez ici pour écrire ..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'input-error' : ''}`}
              value={reasonOfChoice}
              onChange={(e) => setReasonOfChoice(e.target.value)}
            />
            {errors.reasonOfChoice && <p className="input-error-comment text-red-700">{errors.reasonOfChoice}</p>}
          </div>
        </div>

        {/* L'impact de l'idée  */}

        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            BREVE PRESENTATION
          </label>
          <div className="mt-1">
            <input
              id=""
              type="presentation"
              name="impact of idea"
              placeholder="cliquez ici pour écrire ..."
              className={`input-field input-field bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
              focus:border-blue-500 block w-full p-2.5 ${errors.email ? 'input-error' : ''}`}
              value={presentation}
              onChange={(e) => setPresentation(e.target.value)}
            />
            {errors.presentation && <p className="input-error-comment text-red-700">{errors.presentation}</p>}
          </div>
        </div>

        {/* Commentaire */}
        <div>
          <label htmlFor="comment" className="block text-sm font-medium">
            AUTRES COMMENTAIRES (OPTION)
          </label>
          <div className="mt-1">
            <textarea
              id="comment"
              name="comment"
              rows="4"
              className={`input-field block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300
              focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
               dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.comment ? 'input-error' : ''}`}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="cliquez ici pour écrire..."
            />
            {errors.comment && <p className="input-error-comment text-red-700">{errors.comment}</p>}
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300
           font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            ENVOYER
          </button>
        </div>
      </form>
    </div>
  );
};

export default AmbassadorForm;
