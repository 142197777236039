import React from 'react';

const RennesText = () => (
  <div>
    <div className="flex flex-col gap-3 text-jusify text-md lg:mx-20 lg:text-md pb-10 pt-5">

      <p>
        Le choix de la ville de Rennes
        Alors qu’en RDC on souffre d’une économie extravertie
        et d’importation de nourritures, a Rennes il y a un débat
        sur les conséquences du productivisme.
        L’usage à outrance d’engrais chimiques et de pesticides
        pour produire et exporter davantage fait débat.
        Les congolais ont intérêt d’apprendre des succès
        et d’erreurs de Rennais.
      </p>

      <p>
        Lorsqu’on s’engage dans la perspective de
        développement en RDC, il y a la question
        cruciale du manque des routes qui est
        souvent et tout de suite soulevée.
        Il s’agit de la difficulté
        d’évacuation des produits agricoles.
        Je pense que cette question peut être
        résolue. Par exemple, en initiant
        la construction des routes pavées.
        Celles-ci seraient construites territoire
        par territoire. Elles constitueraient en
        elles-mêmes une industrie. D’autant plus
        que les gens vont fabriquer eux-mêmes
        des briques, construire des routes,
        et percevoir des paiements
        à travers les stations de péage.
      </p>

      <h4>Fonds d’investissement</h4>

      <p>
        {`Dans la même optique, nous envisageons la
        création d’un fonds d’investissement direct
        sous forme d’une banque. Pour y arriver,
        avec 200 à 300 personnes qui investissent
        entre 2000 à 5000 Euros,
        nous sommes capables de nous constituer un
        fonds de démarrage. Ensuite, nous aurons
        des partenariats avec d’autres banques
        telles que la BAD, les banques du Brésil,
        d’Indonésie,
        de Chine,
        de Turquie, des pays en partenariats économiques
        divers avec la RDC, notamment dans le cadre de
        la transition écologique en ce qui concerne
        les projets du bassin du Congo.
        d'eviter les erreurs comises par les Bretons.`}
      </p>

    </div>
  </div>
);

export default RennesText;
