import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Acueil from './pages/Acueil';
import Apropos from './pages/Apropos';
import LeChoixdeRennes from './pages/LeChoixdeRennes';
import RdcTerritoires from './pages/RdcTerritoires';
import Programme from './pages/Programme';
import Enregistrement from './pages/Enregistrement';
import Speakers from './pages/Speakers';
import SponsorsAndPartners from './pages/SponsorsAndPartners';
import PageNotFound from './pages/PageNotFound';
import Contact from './pages/Contact';
import Sponsor from './pages/Sponsor';
import Participant from './pages/Participant';
import Faqs from './pages/Faqs';
import Hotels from './pages/Hotels';
import SpecialEvents from './pages/SpecialEvents';
import Awards from './pages/Awards';
import News from './pages/News';
import Gallery from './pages/Gallery';
import Ambassador from './pages/Ambassador';
import ParticipantFees from './pages/ParticipantFees';
import SpeakerSignUp from './pages/SpeakerSignUp';

const App = () => (
  <Routes>
    <Route path="/" element={<Acueil />} />
    <Route path="/Apropos" element={<Apropos />} />
    <Route path="/ChoixDeRennes" element={<LeChoixdeRennes />} />
    <Route path="/RdcTerritoires" element={<RdcTerritoires />} />
    <Route path="/Programme" element={<Programme />} />
    <Route path="/Enregistrement" element={<Enregistrement />} />
    <Route path="/Speakers" element={<Speakers />} />
    <Route path="/speaker-sign-up" element={<SpeakerSignUp />} />
    <Route path="/Faqs" element={<Faqs />} />
    <Route path="/Hotels" element={<Hotels />} />
    <Route path="/Awards" element={<Awards />} />
    <Route path="/News" element={<News />} />
    <Route path="/SpecialEvents" element={<SpecialEvents />} />
    <Route path="/SponsorsAndPartners" element={<SponsorsAndPartners />} />
    <Route path="/Contact" element={<Contact />} />
    <Route path="/Sponsor" element={<Sponsor />} />
    <Route path="/Gallery" element={<Gallery />} />
    <Route path="/Ambassador" element={<Ambassador />} />
    <Route path="/Participant" element={<Participant />} />
    <Route path="/ParticipantFees" element={<ParticipantFees />} />
    <Route path="/*" element={<PageNotFound />} />
  </Routes>
);

export default App;
