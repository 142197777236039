import React from 'react';
import NavBar from '../components/NavBar';
import Provinces from '../components/Provinces';

const RdcTerritoires = () => (
  <div>
    <NavBar />
    <div className="mx-auto px-10 sm:px-20 py:10 my-10">
      <h2 className="mb-12 text-3xl font-bold leadi text-center md:text-left sm:text-4xl">Un aperçu historique</h2>
      <p className="mb-5">
        De l’Etat indépendant du Congo en 1885 à la République Démocratique du Congo
        actuelle, le pays a changé son nom plusieurs fois. A l’indépendance en 1960, il s’est
        appelé République du Congo (1960-64), puis République Démocratique du Congo
        (1964-1971). La politique de l’authenticité du Président Mobutu fait qu’il soit nommé
        République du Zaïre (1971-1997). Enfin, le 17 mai 2017 jusqu’à ce jour, l’ex Zaïre a
        repris le nom de République Démocratique du Congo. Cela, sous la présidence de
        Laurent-Désiré Kabila à celle de Felix Antoine Tshisekedi Tshilombo.
      </p>

      <p className="mb-5">
        La Constitution du 18 février 2006 dite Constitution de la Troisième République
        institue un État unitaire fortement décentralisé. Le pays passe de 11 à 26 provinces.
        Le président et les députés sont élus au suffrage universel direct, alors que les
        Gouverneurs de province le sont par les députés provinciaux.
      </p>

      <p className="mb-5">
        Le démembrement administratif était devenu effectif depuis 2015. Son but est de
        rapprocher l’administration des populations. Le fonctionnement de cette politique
        éprouve de difficultés faute de ressources financières, les provinces étant classées
        en provinces riches ou pauvres selon qu’elles renferment des minerais dans leur
        sous-sol ou pas.
      </p>

      <p className="mb-5">
        Ce n’est pas la première fois que la RDC expérimenté la politique du découpage
        territorial. Notons que le découpage de 2015 ne concernait pas quatre provinces, à
        savoir le Kongo Central, le Sud-Kivu, le Nord-Kivu et Kinshasa. La ville de Kinshasa
        est à la fois la capitale et la province. Il faut également noter que la Tshopo est la
        province la plus vaste, celle du Kasai-Oriental.
      </p>

      <h2 className="mb-5">Voici toutes les provinces et leurs territoires:</h2>

      <Provinces />
    </div>
  </div>
);

export default RdcTerritoires;
