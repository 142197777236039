import React from 'react';
import NavBar from '../components/NavBar';
import ApropoText from '../components/ApropoText';

const Apropos = () => (
  <div>
    <NavBar />
    <div className="flex flex-col justify-center align-center px-5 mx-3 lg:px-0 lg:mx-0 text-bold ">
      <ApropoText />
    </div>
  </div>
);

export default Apropos;
