import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// actions
const ADD_SPEAKER = 'speaker/Store/ADD_SPEAKER';
const GET_SPEAKER = 'speaker/Store/GET_SPEAKER';

const initialState = {
  speakers: [],
};

const Api = 'https://www.rdc145territoires.me/speakers';

// bypassing the devise auth from the frontend api call
const deviseBypasser = {
  'X-Frontend-Request': 'true',
};

// reducer

const speakersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_SPEAKER}/fulfilled`:
      return {
        ...state,
        speakers: [...action.payload],

      };

    case `${ADD_SPEAKER}/fulfilled`:
      return [...state, action.payload];

    default:
      return state;
  }
};

// Action creators

// GET
export const getSpeakers = createAsyncThunk(GET_SPEAKER, async () => {
  const response = await axios.get(Api, {
    headers: {
      'X-Frontend-Request': 'true',
      Accept: 'application/json', // Specify JSON response
    },
  });

  return response.data;
});

// POST
export const addSpeakers = createAsyncThunk(ADD_SPEAKER, async (payload) => {
  await axios.post(Api, payload, { withCredentials: true, headers: deviseBypasser });
  return payload;
});

export default speakersReducer;
