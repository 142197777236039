import React from 'react';

const PaymentFees = () => (
  <div className="font-[sans-serif] relative before:absolute before:w-full before:h-1/2 max-lg:before:h-1/4 before:bg-gradient-to-r before:from-gray-700 before:via-purple-700 before:to-gray-700 before:z-10">
    <div className="max-w-6xl max-md:max-w-xl mx-auto py-10 px-4 relative z-20">
      <div className="text-center">
        <h2 className="text-3xl font-bold text-white mb-2">choisissez un plan adapté à vos besoins</h2>
        <p className="text-sm text-white">{'Catégories d\'accès et leurs avantages'}</p>
      </div>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-8 mt-12">
        <div className="bg-white rounded shadow-[0_2px_10px_-2px_rgba(0,0,0,0.3)] p-6 lg:scale-95">
          <div className="flex items-start">
            <h3 className="text-lg text-gray-800 font-bold border-b-2 border-blue-600 pb-1">accès standard </h3>
            <div className="ml-auto">
              <h3 className="text-gray-800 text-lg font-bold">250$</h3>
            </div>
          </div>

          <div className="mt-8">
            <h4 className="text-base text-gray-800 font-bold mb-4">Plan inclus</h4>
            <ul className="space-y-4 text-gray-600 mb-6">
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Lettre de confirmation par e-mail / format pdf
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Places assises régulières, Pause café et Badge participant.
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                {' Accès communautaire, Accès aux ateliers et Remise d\'un certificat de participant.'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                {'Après l\'événement, Siège sélectionné et Communication directe avec les organisateurs'}

              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                {'Participation de groupe et Logo de l\'organisation ajouté à notre liste de sponsors'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                Assistance de notre personnel pour toute question pendant la conférence.
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                prix spéciaux ou places attribuées (siège) et laissez-passer pour les coulisses,
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                accès aux salons VIP, et entrée anticipée, zones de sièges exclusives,
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                opportunités de rencontre (artistes, conférenciers ou autres invités spéciaux),
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                services gratuits (nourriture et boissons).
              </li>
            </ul>

            <a href="https://buy.stripe.com/3csaGWddp2Ce0zS147" className="w-full mt-8 px-2 py-2 text-sm text-gray-800 rounded  border border-blue-600 bg-transparent hover:bg-blue-600 hover:text-white transition-all">Procéder </a>
          </div>
        </div>
        <div className="bg-white rounded shadow-[0_2px_10px_-2px_rgba(0,0,0,0.3)] p-6 lg:scale-105">
          <div className="flex items-start">
            <h3 className="text-lg text-gray-800 font-bold border-b-2 border-blue-600 pb-1">Accès en groupe</h3>
            <div className="ml-auto">
              <h3 className="text-gray-800 text-lg font-bold">200$</h3>
            </div>
          </div>

          <div className="mt-8">
            <h4 className="text-base text-gray-800 font-bold mb-4">Plan Inclus</h4>
            <ul className="space-y-4 text-gray-600 mb-6">
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Lettre de confirmation par e-mail / format pdf
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Places assises régulières, Pause café et Badge participant.
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                {'Accès communautaire, Accès aux ateliers et Remise d\'un certificat de participant.'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                {'Après l\'événement, Siège sélectionné et Communication directe avec les organisateurs'}

              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Participation de groupe
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                {`Assistance de notre personnel pour toute question
                pendant la conférence et Logo de l'organisation
                ajouté à notre liste de sponsors.`}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                prix spéciaux ou places attribuées (siège) et laissez-passer pour les coulisses,
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                accès aux salons VIP, et entrée anticipée, zones de sièges exclusives,
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                opportunités de rencontre (artistes, conférenciers ou autres invités spéciaux),
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                services gratuits (nourriture et boissons).
              </li>
            </ul>

            <a href="https://buy.stripe.com/3csaGWddp2Ce0zS147" className="w-full mt-8 px-2 py-2 text-sm text-gray-800 rounded  border border-blue-600 bg-transparent hover:bg-blue-600 hover:text-white transition-all">Procéder </a>
            {' '}

          </div>
        </div>
        <div className="bg-white rounded shadow-[0_2px_10px_-2px_rgba(0,0,0,0.3)] p-6 lg:scale-95">
          <div className="flex items-start">
            <h3 className="text-lg text-gray-800 font-bold border-b-2 border-blue-600 pb-1">Accès Premium</h3>
            <div className="ml-auto">
              <h3 className="text-gray-800 text-lg font-bold">300$</h3>
              <p className="text-xs text-gray-600 mt-1">Reserved seating</p>
            </div>
          </div>

          <div className="mt-8">
            <h4 className="text-base text-gray-800 font-bold mb-4">Plan Inclus</h4>
            <ul className="space-y-4 text-gray-600  mb-6">
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Lettre de confirmation par e-mail / format pdf
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Places assises régulières, Pause café et Badge participant.
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                {'Accès communautaire, Accès aux ateliers et Remise d\'un certificat de participant.'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                {' Après l\'événement, Siège sélectionné et Communication directe avec les organisateurs'}

              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                Participation de groupe, prix spéciaux ou places attribuées (siège)
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Assistance de notre personnel pour toute question pendant la conférence.
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                {`Logo de l'organisation ajouté à notre 
                liste de sponsors et laissez-passer pour les coulisses,`}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                accès aux salons VIP, et entrée anticipée, zones de sièges exclusives,
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                opportunités de rencontre (artistes, conférenciers ou autres invités spéciaux),
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                services gratuits (nourriture et boissons).
              </li>
            </ul>

            <a href="https://buy.stripe.com/3csaGWddp2Ce0zS147" className="w-full mt-8 px-2 py-2 text-sm text-gray-800 rounded  border border-blue-600 bg-transparent hover:bg-blue-600 hover:text-white transition-all">Procéder </a>
            {' '}

          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-8 mt-12">
        <div className="bg-white rounded shadow-[0_2px_10px_-2px_rgba(0,0,0,0.3)] p-6 lg:scale-95">
          <div className="flex items-start">
            <h3 className="text-lg text-gray-800 font-bold border-b-2 border-blue-600 pb-1">Accès VIP</h3>
            <div className="ml-auto">
              <h3 className="text-gray-800 text-lg font-bold">500$</h3>
            </div>
          </div>

          <div className="mt-8">
            <h4 className="text-base text-gray-800 font-bold mb-4">Plan Inclus</h4>
            <ul className="space-y-4 text-gray-600  mb-6">
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Lettre de confirmation par e-mail / format pdf
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Places assises régulières, Pause café et Badge participant.
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                {'Accès communautaire, Accès aux ateliers et Remise d\'un certificat de participant.'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                {'Après l\'événement, Siège sélectionné et Communication directe avec les organisateurs'}

              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                Participation de groupe
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Assistance de notre personnel pour toute question pendant la conférence.
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                prix spéciaux ou places attribuées (siège) et laissez-passer pour les coulisses,
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                accès aux salons VIP, et entrée anticipée, zones de sièges exclusives,
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                opportunités de rencontre (artistes, conférenciers ou autres invités spéciaux),
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                {'services gratuits (nourriture et boissons) et Logo de l\'organisation ajouté à notre liste de sponsors.'}
              </li>
            </ul>

            <a href="https://buy.stripe.com/3csaGWddp2Ce0zS147" className="w-full mt-8 px-2 py-2 text-sm text-gray-800 rounded  border border-blue-600 bg-transparent hover:bg-blue-600 hover:text-white transition-all">Procéder </a>
            {' '}

          </div>
        </div>
        <div className="bg-white rounded shadow-[0_2px_10px_-2px_rgba(0,0,0,0.3)] p-6 lg:scale-105">
          <div className="flex items-start">
            <h3 className="text-lg text-gray-800 font-bold border-b-2 border-blue-600 pb-1">Billet acheteur anticipé</h3>
            <div className="ml-auto">
              <h3 className="text-gray-800 text-lg font-bold">150$</h3>
            </div>
          </div>

          <div className="mt-8">
            <h4 className="text-base text-gray-800 font-bold mb-4">Plan Inclus</h4>
            <ul className="space-y-4 text-gray-600 mb-6">
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Lettre de confirmation par e-mail / format pdf
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Places assises régulières, Pause café et Badge participant.
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                {'Accès communautaire, Accès aux ateliers et Remise d\'un certificat de participant.'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                {'Après l\'événement, Siège sélectionné et Communication directe avec les organisateurs'}

              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                {'Participation de groupe et Logo de l\'organisation ajouté à notre liste de sponsors'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                Assistance de notre personnel pour toute question pendant la conférence.
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                prix spéciaux ou places attribuées (siège) et laissez-passer pour les coulisses,
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                accès aux salons VIP,
                et entrée anticipée,
                zones de sièges exclusives,
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                opportunités de rencontre (artistes, conférenciers ou autres invités spéciaux),
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                services gratuits (nourriture et boissons).
              </li>
            </ul>

            <a href="https://buy.stripe.com/3csaGWddp2Ce0zS147" className="w-full mt-8 px-2 py-2 text-sm text-gray-800 rounded  border border-blue-600 bg-transparent hover:bg-blue-600 hover:text-white transition-all">Procéder </a>
            {' '}

          </div>
        </div>
        <div className="bg-white rounded shadow-[0_2px_10px_-2px_rgba(0,0,0,0.3)] p-6 lg:scale-95">
          <div className="flex items-start">
            <h3 className="text-lg text-gray-800 font-bold border-b-2 border-blue-600 pb-1">Accès Local</h3>
            <div className="ml-auto">
              <h3 className="text-gray-800 text-lg font-bold">200$</h3>
              <p className="text-xs text-gray-600 mt-1">{'Pas d\'assistance visa'}</p>
            </div>
          </div>

          <div className="mt-8">
            <h4 className="text-base text-gray-800 font-bold mb-4">Plan Inclus</h4>
            <ul className="space-y-4 text-gray-600 mb-6">
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Lettre de confirmation par e-mail / format pdf
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                Places assises régulières, Pause café et Badge participant.
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-3 p-[3px] bg-blue-500 rounded-full fill-white" viewBox="0 0 24 24">
                  <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000" />
                </svg>
                {'Accès communautaire, Accès aux ateliers et Remise d\'un certificat de participant.'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                {' Après l\'événement, Siège sélectionné et Communication directe avec les organisateurs'}

              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                {'Participation de groupe et Logo de l\'organisation ajouté à notre liste de sponsors'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                {' Assistance de notre personnel pour toute question pendant la conférence.'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                prix spéciaux ou places attribuées (siège) et laissez-passer pour les coulisses,
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                {' accès aux salons VIP, et entrée anticipée, zones de sièges exclusives,'}
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                opportunités de rencontre (artistes, conférenciers ou autres invités spéciaux),
              </li>
              <li className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" className="w-4 mr-3 p-[3px] bg-red-500 rounded-full fill-white" viewBox="0 0 320.591 320.591">
                  <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000" />
                  <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000" />
                </svg>
                services gratuits (nourriture et boissons).
              </li>
            </ul>

            <a href="https://buy.stripe.com/3csaGWddp2Ce0zS147" className="w-full my-10 px-2 py-2 text-sm text-gray-800 rounded  border border-blue-600 bg-transparent hover:bg-blue-600 hover:text-white transition-all">Procéder </a>
            {' '}

          </div>
        </div>
      </div>

    </div>
  </div>
);

export default PaymentFees;
