/* eslint-disable react/no-unknown-property */
import React from 'react';
import CV from '../assets/Programme_CID145TRDC.pdf';

const ProgrammeButton = () => (
  <div>
    <section className="text-gray-800 bg-gray-100">
      <div
        className="container max-w-5xl px-4 py-12 mx-auto"
        bis_skin_checked="1"
      >
        <div className="grid gap-4 mx-4 sm:grid-cols-12" bis_skin_checked="1">
          <div className="col-span-12 sm:col-span-3" bis_skin_checked="1">
            <div
              className="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-pink-400"
              bis_skin_checked="1"
            >
              <h3 className="text-3xl font-semibold">
                {' '}
                Télécharger le programme en PDF
              </h3>
              <span className="text-sm font-bold tracki uppercase text-gray-400">
                <a download="" href={CV} className="">
                  <button
                    type="button"
                    className="mt-3.5 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                  >
                    Download
                  </button>
                </a>
              </span>
            </div>
          </div>
          <div
            className="relative col-span-12 px-4 space-y-6 sm:col-span-9"
            bis_skin_checked="1"
          >
            <div
              className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-700"
              bis_skin_checked="1"
            >
              <h2 className="text-xl font-bold"> MATINEE</h2>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">8H00</h3>
                <p className="mt-3">
                  Arrivée des participants, enregistrement et retrait des
                  badges
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">8h30</h3>
                <p className="mt-3">Installations des participants</p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">9h00</h3>

                <p className="mt-3">
                  {` Discours de Bienvenue des Associations Partenaires, -
                  représentées par l’Association APPLX – Mot de circonstance
                  du Chargé d’Affaires de la RDCongo en France Mot de
                  circonstance des Partenaires de la RDC représentés par le
                  PNUD Mot de circonstance du, Mot de circonstance du Maire de
                  Rennes Métropole Mot de lancement du Conseiller Spécial du
                  Chef de l'Etat en charge des Ressources Extérieures et Suivi
                  des Projets`}
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">9h30</h3>

                <p className="mt-3">
                  Thème :«Modèle économique Breton »
                  {' '}
                  <br />
                  Par Madame
                  Catherine Darrot (Professeure à Agro Campus)
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">10h00</h3>

                <p className="mt-3">
                  Thème: «Productivité ou Productivisme ?»
                  {' '}
                  <br />
                  {' '}
                  par
                  Monsieur Loic Guines (Président de la chambre d’agriculture
                  Bretagne)
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">10h30</h3>

                <p className="mt-3">Pause</p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">10h45</h3>

                <p className="mt-3">
                  Présentation des réformes et actions réalisées par l’état,
                  exemple du Programme de Développement Local par le Ministère
                  du Plan de la RD Congo.
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">11h 15</h3>

                <p className="mt-3">
                  Présentation des Modèles économiques des Métiers développés
                  en Bretagne par les artisans d’Ille-et-Ville
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">
                  12h00 à 13h30
                </h3>

                <p className="mt-3">Pause déjeuner</p>
              </div>
              <h2 className="text-xl font-bold"> APRES-MIDI</h2>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">13h50</h3>
                <p className="mt-3">
                  Etat des lieux du PDL145 T et financement à ce jour
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">14h15</h3>
                <p className="mt-3">
                  Synergie des actions des Ministères avec le PDL-145T
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">14h35</h3>
                <p className="mt-3">
                  {` Les mesures d'accompagnement, les garanties, les facilités
                  pour un entrepreneur congolais et pour tout investisseur
                  <br />
                  ➢ « Présentation des Fiches des Projets sectoriels
                  et invitation au remplissage des Fiches de projet »`}
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">14h55</h3>
                <p className="mt-3">
                  Modèle économique adapté aux Territoires (ROI, TRI, Secteur
                  porteur de croissance, etc.)
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">15h25</h3>
                <p className="mt-3">Renforcement de Partenariat</p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">15h55</h3>
                <p className="mt-3">
                  {` Opportunités d'affaires dans le secteur agricole et de pêche
                  et élevage`}
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">16h25</h3>
                <p className="mt-3">Pause</p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">16h50</h3>
                <p className="mt-3">
                  {`Quel est le modèle optimal de financement pour investir dans
                  l'Industrie du Tourisme rural?`}
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">17h20</h3>
                <p className="mt-3">
                  Compétitivité de PME-PMI établies dans les Territoires
                </p>
              </div>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">
                  17h40 à 18h40
                </h3>
                <p className="mt-3">Ateliers</p>
              </div>
              <ol>
                <li>Agriculture, élevage et Pêche</li>
                <li>Tourisme et Infrastructures</li>
                <li>Formation</li>
                <li>Fonds d’investissement</li>
              </ol>
              <div
                className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-pink-400"
                bis_skin_checked="1"
              >
                <h3 className="text-xl font-semibold tracki">18h40</h3>
                <p className="mt-3">
                  Présentation des recommandations des ateliers en plénière (5
                  min par atelier) et leur remise symbolique au Conseiller
                  Spécial pour transmission au Chef de l’Etat de la RDC19h00
                  Lecture des résolutions de la Conférence et clôture
                  <br />
                  ➢ Fin des assises
                  <br />
                  ➢ Soirée de gala sur invitation
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default ProgrammeButton;
