/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import NavBar from '../components/NavBar';

const News = () => (
  <div>
    <NavBar />
    <div className="my-10 text-gray-100 mx-10 sm:mx-auto">
      {/* From youtube */}

      <div className="container mx-auto p-4 my-2 text-gray-900 max-w-4xl">
        <h2 className="text-2xl font-semibold mb-4">PDL 145 T UN INCUBATEUR DES START-UP A LA HAUTE ECOLE DE COMMERCE DE KIN</h2>

        {/* <!-- YouTube Video Embed Code --> */}
        <div className="w-full aspect-w-16 aspect-h-9">
          {/* <iframe class="w-full h-full" src="https://www.youtube.com/embed/YOUR_VIDEO_ID" frameborder="0" allowfullscreen></iframe> */}
          <iframe
            height="480"
            src="https://www.youtube.com/embed/ihjQNm8r20o?si=AZ6KhzuR3Vg-oX1T"
            className="w-full  aspect-w-16 aspect-h-9"
            title="PDL 145 T UN INCUBATEUR DES START-UP A LA HAUTE ECOLE DE COMMERCE DE KIN"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </div>
      </div>

      <div className="container mx-auto p-4 my-2 text-gray-900 max-w-4xl">
        <h2 className="text-2xl font-semibold mb-4">De Melbourne à Paris, Eric Mwemba mobilise pour les 145 Territoires de la Rép Démocratique du Congo</h2>

        {/* <!-- YouTube Video Embed Code --> */}
        <div className="w-full aspect-w-16 aspect-h-9">
          {/* <iframe class="w-full h-full" src="https://www.youtube.com/embed/YOUR_VIDEO_ID" frameborder="0" allowfullscreen></iframe> */}
          <iframe
            height="480"
            src="https://www.youtube.com/embed/eTwgsCAP7L4?si=Y3WDxoLXdb_81gtm"
            className="w-full  aspect-w-16 aspect-h-9"
            title="De Melbourne à Paris, Eric Mwemba mobilise pour les 145 Territoires de la Rép Démocratique du Congo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </div>
      </div>

      <h2 className="text-4xl font-semibold mb-4 text-gray-900 my-4 text-center">Autres Info</h2>
      {/* End from youtube */}

      <div className="container max-w-4xl px-10 py-6 mb-10 mx-auto rounded-lg shadow-sm bg-gray-900">

        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-400">July 31, 2023</span>
          <a rel="noopener noreferrer" href="https://republique.cd/2023/03/30/tout-savoir-sur-le-programme-de-developpement-local-des-145-territoires/" className="px-2 py-1 font-bold rounded bg-violet-400 text-gray-900">New</a>
        </div>
        <div className="mt-3">
          <a rel="noopener noreferrer" href="https://republique.cd/2023/03/30/tout-savoir-sur-le-programme-de-developpement-local-des-145-territoires/" className="text-2xl font-bold hover:underline">
            Tout savoir sur le Programme de développement local des 145 territoires
          </a>
          <p className="mt-2">
            {`Le Programme de Développement Local des 145 territoires en sigle PDL-145T est une initiative du gouvernement de la République démocratique du Congo
            visant à améliorer le cadre de vie des populations rurales1. Ce projet d’un montant total d’environ 1,6 milliard de dollars vise à autonomiser les
            145 territoires qui répartis dans les 26 provinces que compte le pays et projette à terme de sortir 25 millions de congolais de la pauvreté`}
          </p>
        </div>
        <div className="flex items-center justify-between mt-4">
          <a rel="noopener noreferrer" href="https://republique.cd/2023/03/30/tout-savoir-sur-le-programme-de-developpement-local-des-145-territoires/" className="hover:underline text-violet-400">Lire la suite</a>
          <div>
            <a rel="noopener noreferrer" href="https://www.grandjournalcd.com/author/edmjvnews/" className="flex items-center">
              <img src="https://ik.imagekit.io/pamphilemkpchill/download(1).jpg?updatedAt=1690889809135" alt="avatar" className="object-cover w-10 h-10 mx-4 rounded-full bg-gray-500" />
              <span className="hover:underline text-gray-400">Eric Mwamba</span>
            </a>
          </div>
        </div>
      </div>

      <div className="container max-w-4xl px-10 py-6 mb-10 mx-auto rounded-lg shadow-sm bg-gray-900">
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-400">01 Aout, 2023</span>
          <a rel="noopener noreferrer" href="https://www.radiookapi.net/2022/02/08/actualite/economie/projet-de-developpement-de-145-territoires-166-milliards-usd-pour" className="px-2 py-1 font-bold rounded bg-violet-400 text-gray-900">New</a>
        </div>
        <div className="mt-3">
          <a rel="noopener noreferrer" href="https://www.radiookapi.net/2022/02/08/actualite/economie/projet-de-developpement-de-145-territoires-166-milliards-usd-pour" className="text-2xl font-bold hover:underline">
            1,66 milliards USD pour améliorer la vie des populations rurales
          </a>
          <p className="mt-2">
            Le gouvernement congolais a mis en place le Programme
            de développement local des 145 territoires (PDL) pour
            améliorer les conditions et cadres de vie des populations rurales.
            Le coût du programme, estimé à 1,66 milliards USD,
            sera financé principalement par les ressources du Gouvernement
            \ de la République démocratique du Congo (RDC).
          </p>
        </div>
        <div className="flex items-center justify-between mt-4">
          <a rel="noopener noreferrer" href="https://www.radiookapi.net/2022/02/08/actualite/economie/projet-de-developpement-de-145-territoires-166-milliards-usd-pour" className="hover:underline text-violet-400">Lire la suite</a>
          <div>
            <a rel="noopener noreferrer" href="https://www.grandjournalcd.com/author/edmjvnews/" className="flex items-center">
              <img src="https://ik.imagekit.io/pamphilemkpchill/download(1).jpg?updatedAt=1690889809135" alt="avatar" className="object-cover w-10 h-10 mx-4 rounded-full bg-gray-500" />
              <span className="hover:underline text-gray-400">Eric Mwamba</span>
            </a>
          </div>
        </div>
      </div>

      <div className="container max-w-4xl px-10 py-6 mb-10 mx-auto rounded-lg shadow-sm bg-gray-900">
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-400">01 Aout, 2023</span>
          <p className="px-2 py-1 font-bold rounded bg-violet-400 text-gray-900">New</p>
        </div>
        <div className="mt-3">
          <p className="text-2xl font-bold hover:underline">
            Le gouvernement satisfait de l’évolution des travaux au Kongo-Central
          </p>
          <p className="mt-2">
            L’évolution des travaux exécutés dans le cadre  du Programme
            de développement local des 145 territoires (PDL-145T) dans
            son volet infrastructures est satisfaisante au Kongo-Central,
            en République démocratique du Congo, a déclaré vendredi
            14 juillet 2023, la ministre d’Etat en charge du Plan,
            Judith Suminwa Tuluka.
            « Je suis rentrée  satisfaite du résultat sur le terrain.
            Les travaux avancent normalement. Néanmoins, Songololo,
            Madimba et Muanda accusent un niveau d’exécution faible.
            À ce sujet, des mesures idoines ont été prises pour
            accélérer les travaux », a assuré
            la ministre d’Etat à Kinshasa, au retour
            de sa tournée d’inspection d’une semaine dans le Kongo- Central.
          </p>
          <p className="mt-2">
            {`L’évolution des travaux exécutés dans le cadre  du Programme de développement local des 145 territoires (PDL-145T) dans son volet infrastructures est satisfaisante au Kongo-Central, en République démocratique du Congo, a déclaré vendredi
            14 juillet 2023, la ministre d’Etat en charge du Plan, Judith Suminwa Tuluka.
            « Je suis rentrée  satisfaite du résultat sur le terrain. Les travaux avancent normalement. Néanmoins, Songololo, Madimba et Muanda accusent un niveau d’exécution faible. À ce sujet, des mesures idoines ont été prises pour accélérer les travaux »,
            a assuré
            la ministre d’Etat à Kinshasa, au retour de sa tournée d’inspection d’une semaine dans le Kongo- Central.`}
          </p>

          <p className="mt-2">
            Le coordonnateur national de la Cellule d’exécution pour le
            financement en faveur des États fragiles (CFEF), Alain Lungungu,
            qui accompagnait la ministre d’Etat à cette occasion, a rappelé
            que les entreprises existantes ont été recrutées sur base
            d’une expérience avérée et de leur capacité financière.
            Face à la lenteur avec laquelle les travaux sont exécutés,
            Alain Lungungu a confirmé les décisions prises pour accélérer
            le niveau d’exécution.
            Il s’agit notamment de « mettre en régie » les entreprises
            défaillantes, récupérer la garantie, payer les travaux déjà
            effectués et sous-traiter les travaux en recourant
            à l’expertise locale.
            Avant de regagner Kinshasa, la ministre d’Etat Judith Suminwa
            Tuluka a visité les centres de santé, des écoles
            primaires et bâtiments administratifs dans les
            territoires de Madimba et Kasangulu.
          </p>
          <p className="mt-2">SOURCE: ACP</p>
        </div>
        <div className="flex items-center justify-between mt-4">
          <div>
            <a rel="noopener noreferrer" href="https://www.grandjournalcd.com/author/edmjvnews/" className="flex items-center">
              <img src="https://ik.imagekit.io/pamphilemkpchill/download(1).jpg?updatedAt=1690889809135" alt="avatar" className="object-cover w-10 h-10 mx-4 rounded-full bg-gray-500" />
              <span className="hover:underline text-gray-400">Eric Mwamba</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default News;
