import React from 'react';
import Navbar from '../components/NavBar';

const Hotels = () => (
  <div>
    <Navbar />

    <div className="my-10 ">

      <section className="bg-gray-800 text-gray-100">
        <div className="container flex flex-col-reverse mx-auto lg:flex-row">
          <div className="flex flex-col px-6 py-8 space-y-6 rounded-sm sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 bg-violet-400 text-gray-900">
            <div className="flex space-x-2 sm:space-x-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
              </svg>
              <div className="space-y-2">
                <p className="text-lg font-medium leadi">Visitez</p>
                <a
                  href="https://www.booking.com/city/fr/rennes.fr.html?label=rennes-F1rPZu7FG51FqfA5*dr1pgS389980591908%3Apl%3Ata%3Ap165%3Ap2%3Aac%3A
                        ap%3Aneg%3Afi%3Atiaud-2007851544136%3Akwd-2585430184%3Alp9112654%3Ali%3Adec%3Adm%3Appccp%3DUmFuZG9tSVYkc2RlIyh9YSNxgVPQVI7AMnn1KDvPMRs
                        ;aid=301664;gclid=CjwKCAjwt52mBhB5EiwA05YKowhOcI5iuDmsqfA9MeBLEzkzxH956BpmdoojlRqMOpBqKyTzK-FkSBoC0gYQAvD_BwE;ws="
                  className="leadi"
                >
                  https://www.booking.com/city/fr/rennes.fr.html?label=rennes
                </a>
              </div>
            </div>
            <div className="flex space-x-2 sm:space-x-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
              </svg>
              <div className="space-y-2">
                <p className="text-lg font-medium leadi">DESTINATION RENNES</p>
                <a
                  href="https://www.tourisme-rennes.com/fr/reserver-en-ligne/visites-guidees/?utm_campaign=Billetterie+-+Performance+Max&utm_source=
                                google-perfmax&utm_medium=cpc&gclid=CjwKCAjwt52mBhB5EiwA05YKo6PPf5H6Ca8D8FhtS5O-FICXOVaW60rh78Hz4jyTxerMEPZ0_T1TFRoCFdEQAvD_BwE"
                  className="leadi"
                >
                  https://www.tourisme-rennes.com/fr/reserver-en-ligne/visites-guidees
                </a>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 bg-gray-800">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img src="https://source.unsplash.com/640x480/" alt="" className="rounded-lg shadow-lg bg-gray-500 aspect-video sm:min-h-96" />
            </div>
          </div>
        </div>
      </section>

      <h3 className="text-3xl font-semibold text-center text-gray-900 mt-10">Voir aussi</h3>

      <section className="">
        <div className="container mx-auto   justify-center px-4 space-y-8 md:px-10 lg:space-y-0 lg:flex-row lg:justify-between">
          <h1 className="text-2xl font-semibold leadi text-center lg:text-left">Voyagez</h1>
          <a
            href="https://www.booking.com/city/fr/rennes.fr.html?label=rennes-F1rPZu7FG51FqfA5*dr1pgS389980591908%3Apl%3Ata%3Ap165%3Ap2%3Aac%3A
                    ap%3Aneg%3Afi%3Atiaud-2007851544136%3Akwd-2585430184%3Alp9112654%3Ali%3Adec%3Adm%3Appccp%3DUmFuZG9tSVYkc2RlIyh9YSNxgVPQVI7AMnn1KDvPMRs
                    ;aid=301664;gclid=CjwKCAjwt52mBhB5EiwA05YKowhOcI5iuDmsqfA9MeBLEzkzxH956BpmdoojlRqMOpBqKyTzK-FkSBoC0gYQAvD_BwE;ws="
            className="text-blue-600 py-10"
          >
            Rennes hotel booking
          </a>
        </div>

        <div className="container mx-auto   justify-center px-4 space-y-8 md:px-10 lg:space-y-0 lg:flex-row lg:justify-between">
          <h1 className="text-2xl font-semibold leadi text-center lg:text-left">Destination </h1>
          <a
            href="https://www.tourisme-rennes.com/fr/reserver-en-ligne/visites-guidees/?utm_campaign=Billetterie+-+Performance+Max&utm_source=
                    google-perfmax&utm_medium=cpc&gclid=CjwKCAjwt52mBhB5EiwA05YKo6PPf5H6Ca8D8FhtS5O-FICXOVaW60rh78Hz4jyTxerMEPZ0_T1TFRoCFdEQAvD_BwE"
            className="text-blue-600 py-10"
          >
            Rennes visites guidees
          </a>
        </div>

      </section>
    </div>
  </div>
);

export default Hotels;
